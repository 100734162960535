import React from 'react'
import './Home.css'
import HeroImg from '../components/HeroImg'
import ScrollButton from "../components/ScrollButton";
import ContactButton from '../components/ContactButton';
import Navbar from '../components/Navbar'
import BottomContent from '../components/BottomContent'
import Thank from '../components/Thank';
import ProductSection from '../components/ProductSection';
import ServiceSection from '../components/ServiceSection';
import DarkModeButton from '../components/DarkModeButton';

// import mobileApp from '../assets/img/mobile-application.svg'
// import list from '../assets/img/shopping-list.svg'
// import setting from '../assets/img/settings.svg'

export default function Home() {

    return (
        <>
            <Navbar />
            <HeroImg />
            <ServiceSection />
            <ProductSection />

            {/* Training and industrial program Section */}
            <section>
                <div className="container-fluid my-4" >
                    <div className="heading mx-2" data-aos="fade-up" data-aos-anchor-placement="top-center">
                        <h5 className='text-primary'><b>VEDALYTICS CORPORATE AND INDUSTRIAL TRAINING</b></h5>
                        {/* <div className='bg-warning' style={{ width: '300px', height: '3px', margin: 'auto' }}></div> */}
                        <p className="ser-content mx-auto my-2 " style={{fontSize:'18px', color:'var(--heading_color)'}}>We offer specialized training for IT professionals, whether you're a fresher or an experienced expert. Our programs cover a wide range of technologies, including AI, Data Science, Machine Learning, NLP, Databases, DevOps, Cloud Computing, Python, GCL, CGI, Templeton, and more. Equip yourself with the skills needed to excel in today's fast-paced tech world.</p>
                        {/* <button className="btn btn-dark">Learn More</button> */}
                    </div>
                    {/* <div className="row p-4">
                        <div className="col-12 col-md-3">
                            <div className="programs">1</div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="programs">1</div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="programs">1</div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="programs">1</div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* Corporate Training Section */}
            {/* <section className='mb-5'>
                <div className="bg-warning p-5">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="text-left">
                                <h4 className="ser-heading3"><span>Vedalytics Corporate and Industrial
                                    Training</span></h4>
                                <br />
                                <p className="ser-content3">We provide Training to IT professionals whether you are
                                    Fresher or Experienced. our Expertise are AI/DS/ML/NLP, DB, DevOps, Cloud, Python, GCL,
                                    CGI, Templeton etc.
                                </p>
                                <button className="btn btn-dark">Learn More</button>
                            </div>
                        </div>
                        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <img src={img} alt="img" />
                        </div>
                    </div>
                </div>
            </section> */}

            <Thank/>
            <BottomContent />
            <ContactButton />
            <DarkModeButton />
            <ScrollButton />
           
        </>
    )
}
