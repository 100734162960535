import React from 'react';
import Navbar from '../../components/Navbar';
import BottomContent from '../../components/BottomContent';
import Hero from './Hero';
import ScrollButton from "../../components/ScrollButton";
import ContactButton from '../../components/ContactButton';
import Thank from '../../components/Thank';
import DarkModeButton from '../../components/DarkModeButton';
import '../Industries/IndStyle.css'

export default function VedalyticsManaged() {
    return (
        <>
            {/* Navbar Component */}
            <Navbar />

            {/* Hero Section */}
            <Hero
                title="Vedalytics Managed Engagement"
                des="Let Vedalytics handle your projects end-to-end with full responsibility, execution, and delivery."
            />

            {/* Introduction Section */}
            <section className="py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Solutions, Fully Managed</b></h5>
                    <p className="" style={{color:"var(--heading_color)"}}>
                        The <b>Vedalytics Managed Engagement</b> model allows you to focus on business priorities while we take care of your initiatives—from strategy to deployment.
                    </p>
                </div>
            </section>

            {/* Key Benefits Section */}
            <section className="py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Why Choose Vedalytics Managed Engagement?</b></h5>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">End-to-End Execution</h5>
                                    <p className="card-text">We handle everything from strategy, development, testing, and deployment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Faster Time-to-Market</h5>
                                    <p className="card-text">Leverage our expertise for rapid development and seamless integration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">AI Excellence</h5>
                                    <p className="card-text">Industry-leading AI experts ensure top-quality solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Workflow Section */}
            <section className="py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>How It Works</b></h5>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">1. Strategy & Planning</h5>
                                    <p className="card-text">We define your AI vision and create a roadmap.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">2. Development</h5>
                                    <p className="card-text">Vedalytics builds, tests, and refines your projects.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">3. Deployment & Maintenance</h5>
                                    <p className="card-text">We ensure seamless AI integration and continuous improvements.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Closing Statement */}
            <Thank />

            {/* Bottom Content */}
            <BottomContent />

            {/* Dark Mode Button */}
            <DarkModeButton />

            {/* Contact Button */}
            <ContactButton />

            {/* Scroll to Top Button */}
            <ScrollButton />
        </>
    );
}
