import React from 'react';
import Navbar from '../../components/Navbar';
import BottomContent from '../../components/BottomContent';
import Hero from './Hero';
import ScrollButton from "../../components/ScrollButton";
import ContactButton from '../../components/ContactButton';
import Thank from '../../components/Thank';
import DarkModeButton from '../../components/DarkModeButton';
import './IndStyle.css'

export default function EducationSystem() {
    return (
        <>
            {/* Navbar Component */}
            <Navbar />

            {/* Hero Section */}
            <Hero
                title='AI-Driven Education Solutions'
                des='Revolutionizing learning experiences with innovative AI technologies.'
            />

            {/* Introduction Section */}
            <section className="education-introduction py-5 ">
                <div className="container">
                <h5 className="mb-4 text-primary text-uppercase"><b>Transforming Education with AI Solutions</b></h5>
                <p className="" style={{color:"var(--heading_color)"}}>
                    At Vedalytics, we craft AI-driven educational solutions that redefine learning experiences, boost educational outcomes, and enhance accessibility. Our team of specialists leverages advanced artificial intelligence to develop innovative products and services, revolutionizing how education is delivered worldwide.
                </p>
                </div>
            </section>

            {/* AI in Education Overview */}
            <section className="ai-in-education py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>AI-Driven Educational Solutions</b></h5>
                    <p className="mb-4" style={{color:"var(--heading_color)"}}>
                    Revolutionizing education through AI, our solutions offer tailored learning experiences, streamline administrative processes, and support adaptive learning environments. Our cutting-edge tools are designed to:
                    </p>
                    <div className="row">
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-body text-center">
                                <h5 className="card-title">Adaptive Learning</h5>
                                <p className="card-text">Customizing educational content to match individual learning styles and progress.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-body text-center">
                                <h5 className="card-title">Smart Tutoring Systems</h5>
                                <p className="card-text">Providing personalized guidance and instant feedback through performance analysis.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-body text-center">
                                <h5 className="card-title">Data-Driven Insights</h5>
                                <p className="card-text">Gaining valuable insights to optimize student performance and educational outcomes.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            {/* Benefits of AI in Education */}
            <section className="ai-benefits-in-education py-5" >
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>The Benefits of AI in Education</b></h5>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <strong>Increased Efficiency:</strong> Automating repetitive tasks and administrative duties frees up educators to focus on personalized student engagement.
                        </li>
                        <li className="list-group-item">
                            <strong>Better Learning Outcomes:</strong> AI helps students learn at their own pace, ensuring they get the attention they need to succeed.
                        </li>
                        <li className="list-group-item">
                            <strong>Scalable Solutions:</strong> AI systems can be implemented across various educational environments, from K-12 to higher education institutions.
                        </li>
                        <li className="list-group-item">
                            <strong>Improved Engagement:</strong> Interactive and adaptive AI-based tools can keep students engaged and motivated to learn, enhancing the overall learning experience.
                        </li>
                    </ul>
                </div>
            </section>

            {/* AI Solutions for Institutions */}
            <section className="ai-solutions-for-institutions py-5">
                <div className="container">
                <h5 className="mb-4 text-primary text-uppercase"><b>Empowering Educational Institutions with AI Solutions</b></h5>
                <p className="mb-4" style={{color:"var(--heading_color)"}}>
                    Our comprehensive AI solutions are specifically designed to support educational institutions by offering:
                </p>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card shadow-sm mb-4">
                            <div className="card-body">
                                <h5 className="card-title">Smart Learning Management Systems (LMS)</h5>
                                <p className="card-text">LMS that personalize learning experiences, track progress, and deliver tailored resources to enhance student engagement.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card shadow-sm mb-4">
                            <div className="card-body">
                                <h5 className="card-title">Intelligent Grading Systems</h5>
                                <p className="card-text">Automated grading solutions that provide prompt feedback to students and educators, streamlining assessment processes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card shadow-sm mb-4">
                            <div className="card-body">
                                <h5 className="card-title">Next-Gen Virtual Classrooms</h5>
                                <p className="card-text">Interactive virtual environments, offering chatbots, content recommendations, and real-time performance analysis.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card shadow-sm mb-4">
                            <div className="card-body">
                                <h5 className="card-title">Inclusive Education with AI Support</h5>
                                <p className="card-text">Specialized tools designed to assist learners with disabilities, promoting accessibility and equal learning opportunities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>

            {/* Challenges in AI Adoption */}
            <section className="challenges-in-ai-education py-5 ">
            <div className="container">
                <h5 className="mb-4 text-primary text-uppercase" ><b>Barriers to AI Adoption in Education</b></h5>
                <ul className="list-group">
                    <li className="list-group-item">
                        <strong>Data Security:</strong> Safeguarding sensitive student information while utilizing AI to enhance educational outcomes.
                    </li>
                    <li className="list-group-item">
                        <strong>System Compatibility:</strong> Integrating AI solutions smoothly with existing educational technologies and platforms.
                    </li>
                    <li className="list-group-item">
                        <strong>User Acceptance:</strong> Addressing concerns and building trust among educators and stakeholders regarding the reliability of AI applications.
                    </li>
                </ul>
            </div>
            </section>

            {/* Future Vision for AI in Education */}
            <section className="future-vision-ai-education py-5">
                <div className="container">
                <h5 className="mb-4 text-primary text-uppercase">
                    <b>Shaping the Next Generation of Learning Through AI Innovation</b>
                </h5>
                <p className="" style={{color:"var(--heading_color)"}}>
                    We believe artificial intelligence will fundamentally redefine educational paradigms. Advanced algorithms will revolutionize knowledge acquisition, optimize institutional operations, and deliver predictive analytics that informs strategic choices. Our mission focuses on developing cutting-edge solutions that enable both instructors and learners to excel in an evolving academic environment powered by intelligent systems. By bridging technological capabilities with educational needs, we aim to cultivate environments where adaptive learning and operational efficiency coexist seamlessly.
                </p>
                </div>
            </section>

            {/* Closing Statement */}
            <Thank />

            {/* Bottom Content */}
            <BottomContent />

            {/* Dark Mode Button */}
            <DarkModeButton />

            {/* Contact Button */}
            <ContactButton />

            {/* Scroll to Top Button */}
            <ScrollButton />
        </>
    );
}
