import React from 'react'
import Navbar from '../components/Navbar'
import BottomContent from '../components/BottomContent'
import ScrollButton from "../components/ScrollButton";
import Thank from '../components/Thank';
import AboutImg from '../assets/img/AboutImg2.png'
import { Link } from 'react-router-dom'
import DarkModeButton from '../components/DarkModeButton'
import ContactButton from '../components/ContactButton'
export default function About() {
  
  return (
    <>
      <Navbar />

      {/* hero section*/}

      <div className="main-area  p-4" style={{background: 'var(--hero_linear)',marginTop:'66px'}}>
          <div className="row mx-auto container my-5">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-3 mt-sm-3 mt-md-5 mt-lg-5 mt-xl-5">
                    <h3 className="f-color-light px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1 text-primary">About Vedalytics</h3>
                    <h3 className="head-main px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-warning hf">Welcome to the Future of Development & Technology</h3>
                    <p className="f-color-light px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1" style={{color:'var(--content_color)'}}>
                        At Vedalytics, we turn visionary ideas into innovative solutions, blending creativity and technology to deliver exceptional results. Our mission is to craft dynamic, impactful digital experiences that build lasting brands online.
                    </p>
                    <span className="px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1"></span>
                </div>
              <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block justify-content-center align-self-center col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12" style={{ height: '60vh' }}>
                  <img className="bg-image img-fluid" src={AboutImg} alt="bg" style={{ width: '100%', height: '100%', objectFit: 'cover', marginLeft:'50px'}} />
              </div>
          </div>
      </div>

      {/* meet our team section */}
      <section>
        <div className="container mt-5"  data-aos="fade-up" data-aos-anchor-placement="top-center">
          <div className="heading text-center">
            <h4 className='text-primary'>Meet Our Team</h4>
            <div className='bg-warning' style={{ width: '300px', height: '3px', margin: 'auto' }}></div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card text-center my-4 border-0 " style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' ,background: 'var(--card_color)', color:'var(--content_color)'}}>
                <div className="card-body">
                  <h5 className="card-title"><b>Mr. Ramakant Roy</b></h5>
                  <h6 className="card-subtitle mb-2 ">Co-founder and Director</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card text-center my-4 border-0 " style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',background: 'var(--card_color)', color:'var(--content_color)' }}>
                <div className="card-body">
                  <h5 className="card-title"><b>Mr. Aditya Kumar</b></h5>
                  <h6 className="card-subtitle mb-2 ">Director (Operation)</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card text-center my-4 border-0 " style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',background: 'var(--card_color)', color:'var(--content_color)' }}>
                <div className="card-body">
                  <h5 className="card-title"><b>Dr. Gajendra Prasad Singh</b></h5>
                  <h6 className="card-subtitle mb-2 ">Director (Legal)</h6>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card text-center my-4 border-0 " style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',background: 'var(--card_color)', color:'var(--content_color)' }}>
                <div className="card-body">
                  <h5 className="card-title"><b>Mr. Kapil Kale</b></h5>
                  <h6 className="card-subtitle mb-2 ">Director (Sales & Marketing)</h6>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* Another Section */}
      <section className='' >
                <div className="p-5 text-center" style={{backgroundColor:'#3BB893'}}  data-aos="fade-up" data-aos-anchor-placement="top-center">
                   <h3 className='text-light'>Having Projects ?</h3>
                   <p  className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
                   <div className="button">
                    <button className='btn'><Link style={{textDecoration:'none',color:'green'}} to='/scheduledemo'>Schedule Demo</Link></button>
                   </div>
                </div>
            </section>
      <Thank/>
      <BottomContent />
      <ContactButton />
      <DarkModeButton />
      <ScrollButton />
           
    </>
  )
}
