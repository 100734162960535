import React from 'react';
import Navbar from '../../components/Navbar';
import BottomContent from '../../components/BottomContent';
import Hero from './Hero';
import ScrollButton from "../../components/ScrollButton";
import ContactButton from '../../components/ContactButton';
import Thank from '../../components/Thank';
import DarkModeButton from '../../components/DarkModeButton';
import './IndStyle.css'

export default function HealthCare() {
    return (
        <>
            {/* Navbar Component */}
            <Navbar />

            {/* Hero Section */}
            <Hero
                title='AI-Powered Healthcare Solutions'
                des='Revolutionizing healthcare with advanced AI technology to improve patient care and operational efficiency.'
            />

            {/* Introduction Section */}
            <section className="healthcare-introduction py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Transforming Healthcare with AI Solutions</b></h5>
                    <p style={{color:"var(--heading_color)"}}>
                        At Vedalytics, we integrate AI-driven innovations into healthcare, enhancing patient outcomes, improving diagnosis accuracy, and optimizing hospital operations. Our solutions empower healthcare providers with intelligent, data-driven decision-making capabilities.
                    </p>
                </div>
            </section>

            {/* AI in Healthcare Overview */}
            <section className="ai-in-healthcare py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>AI-Driven Healthcare Solutions</b></h5>
                    <p style={{color:"var(--heading_color)"}}>
                        Our AI solutions redefine healthcare by enabling predictive diagnostics, streamlining workflows, and automating processes. Key areas of impact include:
                    </p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">AI Diagnostics</h5>
                                    <p className="card-text">Enhancing disease detection and medical imaging analysis with machine learning.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Predictive Analytics</h5>
                                    <p className="card-text">Forecasting patient outcomes and identifying potential health risks through AI models.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Automated Healthcare</h5>
                                    <p className="card-text">Streamlining administrative tasks to improve hospital efficiency and patient experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Benefits of AI in Healthcare */}
            <section className="ai-benefits-in-healthcare py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Benefits of AI in Healthcare</b></h5>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>Enhanced Accuracy:</strong> AI improves diagnostics and treatment plans by reducing human error.</li>
                        <li className="list-group-item"><strong>Faster Processing:</strong> AI-powered systems speed up patient diagnoses and administrative workflows.</li>
                        <li className="list-group-item"><strong>Cost Efficiency:</strong> Reducing manual effort and optimizing hospital resources through AI automation.</li>
                        <li className="list-group-item"><strong>Improved Patient Outcomes:</strong> AI-driven insights support personalized treatment strategies for better healthcare results.</li>
                    </ul>
                </div>
            </section>

            {/* AI Solutions for Healthcare Providers */}
            <section className="ai-solutions-for-healthcare py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>AI Solutions Tailored for Healthcare Providers</b></h5>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card shadow-sm mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">AI-Driven Patient Monitoring</h5>
                                    <p className="card-text">Continuous monitoring systems that detect anomalies and provide real-time alerts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card shadow-sm mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">AI-Powered Virtual Assistants</h5>
                                    <p className="card-text">Chatbots and voice assistants for scheduling, medication reminders, and patient queries.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card shadow-sm mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Automated Medical Imaging</h5>
                                    <p className="card-text">Advanced AI tools to assist in MRI, CT scans, and X-ray analysis for early disease detection.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card shadow-sm mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Smart Health Records</h5>
                                    <p className="card-text">AI-powered electronic health records that optimize patient data management and accessibility.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Challenges in AI Adoption */}
            <section className="challenges-in-ai-healthcare py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Challenges in AI Adoption for Healthcare</b></h5>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>Data Privacy:</strong> Ensuring secure patient data storage and compliance with healthcare regulations.</li>
                        <li className="list-group-item"><strong>Integration Barriers:</strong> Implementing AI solutions seamlessly into existing hospital infrastructure.</li>
                        <li className="list-group-item"><strong>Trust and Training:</strong> Educating healthcare professionals on AI benefits and overcoming skepticism in AI-assisted care.</li>
                    </ul>
                </div>
            </section>

            {/* Future Vision */}
            <section className="future-vision-ai-healthcare py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>The Future of AI in Healthcare</b></h5>
                    <p style={{color:"var(--heading_color)"}}>
                        AI is shaping the future of healthcare by enabling precision medicine, robotic surgeries, and AI-assisted diagnostics. Our commitment lies in developing intelligent healthcare ecosystems that drive efficiency, accuracy, and better patient care outcomes.
                    </p>
                </div>
            </section>

            {/* Closing Statement */}
            <Thank />
            <BottomContent />
            <DarkModeButton />
            <ContactButton />
            <ScrollButton />
        </>
    );
}
