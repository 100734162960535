// import React from 'react'
// import Navbar from '../../components/Navbar'
// import BottomContent from '../../components/BottomContent'
// import Footer from '../../components/Footer'
// import Hero from './Hero'
// import AppDevImg from '../../assets/img/AppDeve.png'
// import WebDev from '../../assets/img/AppDevHero.png'
// import ServicesCard from '../../components/ServicesCard'
// import ScrollButton from "../../components/ScrollButton";
 
// import { Link } from 'react-router-dom'
// import ContactButton from '../../components/ContactButton'
// import DarkModeButton from '../../components/DarkModeButton'
// import Thank from '../../components/Thank'

// export default function AppDev() {
//   const HeroImgAppDev = {
//     heading: 'Application Development',
//     subheading: 'Welcome to the World of Development & Technology',
//     text: `Are you looking to create a powerful and user-friendly application for your business? Our expert team of application developers can help you bring your ideas to life `,
//     image: WebDev,
//     validate: false
//   }

//   const appdevcontext = [
//     {
//       img: AppDevImg,
//       heading: 'Custom Application Development',
//       text: 'We specialize in custom application development, tailoring solutions to meet the unique requirements of your business. Our developers work closely with you to understand your goals and objectives, ensuring that the final product aligns perfectly with your vision. From concept and design to development and deployment, we provide end-to-end services to create highly functional applications',
//       bg: 'primary',
//       headingColor: 'dark',
//       textColor: 'light'
//     },
//     {
//       img: AppDevImg,
//       heading: 'Mobile Application Development',
//       text: `In today's mobile-driven world, having a mobile application is essential to reach and engage with your audience. Our mobile application development services cover both iOS and Android platforms, utilizing native or hybrid frameworks to deliver exceptional user experiences. We focus on creating intuitive and visually appealing mobile apps that captivate users and drive business growth.`,
//       bg: 'warning',
//       headingColor: 'dark',
//       textColor: 'light'
//     },
//     {
//       img: AppDevImg,
//       heading: 'Web Application Development',
//       text: `Web applications offer a dynamic and accessible way to interact with your customers. Our web application development expertise spans across various technologies and frameworks, including React, Angular, Node.js, and more. We build responsive and scalable web applications that are optimized for speed, performance, and security, ensuring a seamless user experience across different devices and browsers.`,
//       bg: 'danger',
//       headingColor: 'warning',
//       textColor: 'light'
//     },
//     {
//       img: AppDevImg,
//       heading: 'E-commerce Application Development',
//       text: `If you're planning to establish an online store, we can help you develop a feature-rich e-commerce application. Our team has experience working with popular e-commerce platforms like Magento, WooCommerce, Shopify, and more. We integrate secure payment gateways, implement inventory management systems, and create user-friendly interfaces to provide a seamless shopping experience for your customers.`,
//       bg: 'success',
//       headingColor: 'warning',
//       textColor: 'light'
//     },
//     {
//       img: AppDevImg,
//       heading: 'Application Integration and Migration',
//       text: `Already have an existing application but need to enhance its functionality or integrate it with other systems? We offer application integration services to streamline your business processes and improve efficiency. Additionally, if you're looking to migrate your application to a new platform or upgrade to the latest technology stack, our experts can handle the entire migration process seamlessly.`,
//       bg: 'primary',
//       headingColor: 'dark',
//       textColor: 'light'
//     },
//     {
//       img: AppDevImg,
//       heading: 'Maintenance and Support',
//       text: `We understand that application development is an ongoing process, and we provide reliable maintenance and support services to ensure your application runs smoothly. Our team offers regular updates, bug fixes, and performance enhancements to keep your application up-to-date and secure. We are dedicated to providing prompt assistance and resolving any issues that may arise, allowing you to focus on your core business activities.`,
//       bg: 'warning',
//       headingColor: 'dark',
//       textColor: 'light'
//     }]
//   return (
//     <>
//       <Navbar />
//       <Hero
//         heading={HeroImgAppDev.heading}
//         subheading={HeroImgAppDev.subheading}
//         text={HeroImgAppDev.text}
//         img={WebDev}
//         validate={HeroImgAppDev.validate}
//       />

 
//       <div className="container-fluid">
//         {
//           appdevcontext.map((card, i) => {
//             return (
//               <div className="row">
//                 <div className="col-md-12 my-1" data-aos="fade-up" data-aos-anchor-placement="top-center">
//                   <ServicesCard key={i} img={card.img} heading={card.heading} text={card.text} bg={card.bg} headingColor={card.headingColor} textColor={card.textColor} />
//                 </div>
//               </div>
//             )
//           })
//         }
//       </div>
//       {/* Another Section */}
//       <section className='' >
//         <div className="p-5 text-center" style={{ backgroundColor: '#3BB893' }} data-aos="fade-up" data-aos-anchor-placement="top-center">
//           <h3 className='text-light'>Having Projects ?</h3>
//           <p className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
//           <div className="button">
//             <button className='btn'><Link style={{ textDecoration: 'none', color: 'green' }} to='/scheduledemo'>Schedule Demo</Link></button>
//           </div>
//         </div>
//       </section>
//       <Thank />
//       <ContactButton />
//       <BottomContent />
//       <DarkModeButton />
//       <ScrollButton/>
      
//     </>
//   )
// }

import React from 'react'
import Navbar from '../../components/Navbar'
import BottomContent from '../../components/BottomContent'
import Hero from './Hero'
import HeroImg from '../../assets/img/DigitalHero.png'
import ScrollButton from "../../components/ScrollButton"
import { Link } from 'react-router-dom'
import ContactButton from '../../components/ContactButton'
import DarkModeButton from '../../components/DarkModeButton'
import Thank from '../../components/Thank'
import { FaLightbulb, FaRobot, FaBrain, FaShieldAlt, FaComments, FaNetworkWired } from 'react-icons/fa';


export default function Digital() {
  const HeroImgDigital = {
    heading: 'Digital Strategy and Innovation',
    subheading: 'Revolutionizing Businesses with Advanced Digital Solutions',
    text: `Harness the power of innovation to elevate your business with our comprehensive digital strategy services. From AI-driven automation to state-of-the-art engineering practices, we equip your organization with the most advanced technologies and transformative solutions.`,
    image: HeroImg,
    validate: false
  }

  // Service data with icons instead of images
  const digitalServices = [
    {
      icon: <FaLightbulb size={32} />,
      heading: 'Digital Strategy and Innovation',
      text: `Reimagine your business with our innovative digital strategy solutions. Leverage cutting-edge technologies to foster transformation and maintain a competitive edge. Our experts work alongside you to develop a strategic roadmap that seamlessly integrates digital innovation with your business objectives.`,
    },
    {
      icon: <FaRobot size={32} />,
      heading: 'AI Automation Practices - RPA/CPA',
      text: `Automate repetitive tasks and enhance operational efficiency with our AI-driven automation solutions. We implement Robotic Process Automation (RPA) and Cognitive Process Automation (CPA) to streamline workflows and reduce manual effort, enabling smarter and faster decision-making.`,

    },
    {
      icon: <FaBrain size={32} />,
      heading: 'AI Data Science Practices - DS/ML/DL, GenAI',
      text: `Leverage the power of data with our advanced data science and machine learning practices. From predictive analytics to generative AI, we develop data-driven solutions that provide valuable insights and support strategic decision-making.`,
    
    },
    {
      icon: <FaShieldAlt size={32} />,
      heading: 'Quantum Computing & Cybersecurity',
      text: `Stay ahead in the digital age with cutting-edge quantum computing and robust cybersecurity solutions. Our experts ensure that your data and systems are protected while exploring new computational possibilities with quantum technology.`,
      
    },
    {
      icon: <FaComments size={32} />,
      heading: 'Chatbot Solutions',
      text: `Boost customer engagement and streamline support with smart chatbot solutions. We develop AI-powered conversational systems that effectively interpret and respond to user inquiries, delivering smooth and efficient interactions to elevate user experience.`,
    },
    {
      icon: <FaNetworkWired size={32} />,
      heading: 'Blockchain & IoT Practices',
      text: `Secure your data and connect your devices with our blockchain and IoT solutions. We develop decentralized applications and integrate IoT frameworks to create secure, reliable, and efficient ecosystems for your business.`,
      
    }
  ]

  return (
    <>
      <Navbar />
      <Hero
        heading={HeroImgDigital.heading}
        subheading={HeroImgDigital.subheading}
        text={HeroImgDigital.text}
        img={HeroImg}
        validate={HeroImgDigital.validate}
      />

      {/* Services Section - Modern Card Layout */}
      <div className="container py-5">        
        <div className="row g-4">
          {digitalServices.map((service, index) => (
            <div className="col-md-6 mb-4" key={index} data-aos="fade-up" data-aos-delay={index * 100}>
              <div className="card h-100 border-0 shadow-sm">
                <div className="card-body p-4">
                  {/* <div className={`icon-circle bg-light bg-opacity-10 mb-4`} style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: `black`,
                  }}>
                    {service.icon}
                  </div> */}
                  <h3 className={`h4 mb-3 fw-bold`}>{service.heading}</h3>
                  <p className="text-muted mb-3">{service.text}</p>
                  <Link to="/scheduledemo" className="btn btn-sm btn-outline-primary">
                      Learn More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>


      {/* Call to Action Section - Improved */}
      <section className='' >
        <div className="p-5 text-center" style={{ backgroundColor: '#3BB893' }} data-aos="fade-up" data-aos-anchor-placement="top-center">
          <h3 className='text-light'>Having Projects ?</h3>
          <p className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
          <div className="button">
            <button className='btn'><Link style={{ textDecoration: 'none', color: 'green' }} to='/scheduledemo'>Schedule Demo</Link></button>
          </div>
        </div>
      </section>

      <Thank />
      <ContactButton />
      <BottomContent />
      <DarkModeButton />
      <ScrollButton/>
      
      {/* Custom CSS */}
      <style>{`
        .card {
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          background: var(--card_color);
          color: var(--heading_color);
        }
        .card:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        }
      `}</style>
    </>
  )
}