// import React from 'react';
// import guru from '../assets/img/c1.png'
// import prabha from '../assets/img/c2.png'
// import vidya from '../assets/img/c3.png'
// import suraksha from '../assets/img/c4.png'
// import MantrAi from '../assets/img/MantrAi.jpg'
// import './ProductSection.css'

// const ProductCard = ({ frontImage, title, description }) => {
//   return (
//     <div className="col-12 col-sm-6 col-md-3 p-3">
//       <div className="flip-card h-full">
//         <div className="flip-card-inner">
//           {/* Front Side */}
//           <div className="flip-card-front bg-white p-3 rad ">
//             {/* <div className="card border-0 rad h-full"> */}
//               <div className="card-body d-flex flex-column justify-content-center">
//                 <div className="d-flex justify-content-center mb-4" >
//                   <img src={frontImage} alt={title} style={{width:'120px'}}/>
//                 </div>
//                 <h6 className="card-title mx-2 text-center">{title}</h6>
//                 {/* {features.map((feature, index) => (
//                   <div key={index} className="flex items-center mt-2">
//                     <i className="bi bi-check-circle-fill text-primary"></i>
//                     <span className="text-sm ml-2">{feature}</span>
//                   </div>
//                 ))} */}
//               </div>
//             {/* </div> */}
//           </div>
          
//           {/* Back Side */}
//           <div className="flip-card-back bg-light text-dark p-4 rad">
//             <div className="h-full flex flex-col justify-center">
//               <h6 className=" mb-3"><b>{title}</b></h6>
//               <p className="text-sm" style={{ fontSize:'18px'}}>{description}</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ProductSection = () => {
//   const products = [
//     {
//       image: guru,
//       title: "ChatBot (Guru) - ChatBot Solutions",
//       features: ["AI-ChatBot Solutions", "Voice & text based"],
//       description: "Advanced AI-powered chatbot solution offering seamless voice and text-based interactions. Perfect for customer service automation and engagement enhancement."
//     },
//     {
//       image: prabha,
//       title: "Prabhaamandal - IOT and Blockchain Solutions",
//       features: ["Digital Wearables Advisor", "Location based Services"],
//       description: "Innovative IoT and blockchain integration platform providing secure, decentralized solutions for wearable technology and location-based services."
//     },
//     {
//       image: vidya,
//       title: "Vidya - Applied AI in Education System",
//       features: ["Cognitive Solution", "Guided communication"],
//       description: "Revolutionary AI-driven educational platform delivering personalized learning experiences through cognitive solutions and guided communication pathways."
//     },
//     {
//       image: suraksha,
//       title: "Suraksha - Security Framework By Vedalytics",
//       features: ["Enabling Auth & Auth", "Flexible integration"],
//       description: "Comprehensive security framework offering robust authentication and authorization solutions with flexible integration capabilities for enterprise systems."
//     },
//     {
//       image: vidya,
//       title: "MantrAI - AI-powered coding assistant",
//       features: ["code generation, debugging, and development workflow optimization"],
//       description: "MantrAI is an AI-powered developer tool designed to accelerate coding and streamline the development process.It assists developers with intelligent code generation."
//     },
//     {
//     image: suraksha,
//     title: "VedaQGPT - AI-powered coding assistant",
//     features: ["code generation, debugging, and development workflow optimization"],
//     description: "MantrAI is an AI-powered developer tool designed to accelerate coding and streamline the development process."
//     },
//     {
//     image: suraksha,
//     title: "LLM Sandbox - AI-powered coding assistant",
//     features: ["Platform for creating and testing LLM-based solutions"],
//     description: " The LLM Sandbox is a specialized platform designed to boost the productivity of engineering teams by offering a customized environment for creating and testing LLM-based solutions."
//     }
//   ];

//   return (
//     <div className="container-fluid py-4" style={{ 
//       background: 'linear-gradient(270deg, #000000 0%, #556261 100%)',
//       paddingTop: '15px',
//       paddingBottom: '25px'
//     }}>
//       <div className="heading mx-2">
//         <h5 className="text-primary mt-4"><b>PRODUCTS</b></h5>
//         <p className="ser-content mx-auto my-3 hcs hf text-light">
//           Next-gen solutions to drive business growth
//         </p>
//       </div>

//       <div className="content row p-4">
//         {products.map((product, index) => (
//           <ProductCard
//             key={index}
//             frontImage={product.image}
//             title={product.title}
//             description={product.description}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProductSection;

import React, { useState, useEffect, useRef } from 'react';
import guru from '../assets/logo/GuruLogo.png';
import vidya from '../assets/logo/VidyaLogo.png';
import suraksha from '../assets/logo/SurakshaLogo.png';
import mantrAi from '../assets/logo/MantraiLogo.png';
import vedaqgpt from '../assets/logo/VedaqgptLogo.png';
import llmsandbox from '../assets/logo/SandboxLogo.png'
import './ProductSection.css'

const ProductCard = ({ frontImage, title, description }) => {
  return (
    <div className="flip-card h-full">
      <div className="flip-card-inner">
        {/* Front Side */}
        <div className="flip-card-front  p-3 rad" style={{background:'var(--card_color)',color:'var(--content_color)'}}>
          <div className="card-body d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center mb-4">
              <img src={frontImage} alt={title} style={{ width: '120px', borderRadius:'25px' }} />
            </div>
            <h6 className="card-title mx-2 text-center">{title}</h6>
          </div>
        </div>
        
        {/* Back Side */}
        <div className="flip-card-back  text-dark p-4 rad" style={{background:'var(--card_color)'}}>
          <div className="h-full flex flex-col justify-center" style={{background:'var(--card_color)',color:'var(--content_color)'}}>
            <h6 className="mb-3"><b>{title}</b></h6>
            <p className="text-sm" style={{ fontSize: '18px' }}>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductSection = () => {
  const products = [
    {
      image: guru,
      title: "ChatBot (Guru) - ChatBot Solutions",
      description: "Advanced AI-powered chatbot solution offering seamless voice and text-based interactions. Perfect for customer service automation and engagement enhancement."
    },
    // {
    //   image: prabha,
    //   title: "Prabhaamandal - IOT and Blockchain Solutions",
    //   description: "Innovative IoT and blockchain integration platform providing secure, decentralized solutions for wearable technology and location-based services."
    // },
    {
      image: vidya,
      title: "Vidya - Applied AI in Education System",
      description: "Revolutionary AI-driven educational platform delivering personalized learning experiences through cognitive solutions and guided communication pathways."
    },
    {
      image: suraksha,
      title: "Suraksha - Security Framework By Vedalytics",
      description: "Comprehensive security framework offering robust authentication and authorization solutions with flexible integration capabilities for enterprise systems."
    },
    {
      image: mantrAi,
      title: "MantrAI - AI-powered coding assistant",
      description: "MantrAI is an AI-powered developer tool designed to accelerate coding and streamline the development process. It assists developers with intelligent code generation."
    },
    {
      image: vedaqgpt,
      title: "VedaQGPT - Secure Local Generative AI",
      description: "VedaQGPT is a secure local AI solution that harnesses your data to streamline operations and boost engagement with advanced Generative AI."
    },
    {
      image: llmsandbox,
      title: "LLM Sandbox - AI-powered coding assistant",
      description: "The LLM Sandbox is a specialized platform designed to boost the productivity of engineering teams by offering a customized environment for creating and testing LLM-based solutions."
    }
  ];

  const [startIndex, setStartIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const carouselRef = useRef(null);
  const autoplayIntervalRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  
  // Get visible products (showing 4 at a time)
  const visibleProducts = () => {
    const result = [];
    for (let i = 0; i < 4; i++) {
      const index = (startIndex + i) % products.length;
      result.push({
        ...products[index],
        position: i
      });
    }
    return result;
  };

  // Handler for next button - smoother transition
  const handleNext = () => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    
    setTimeout(() => {
      setStartIndex((prevIndex) => (prevIndex + 1) % products.length);
      setIsTransitioning(false);
    }, 300);
  };

  // Handler for previous button
  const handlePrev = () => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    
    setTimeout(() => {
      setStartIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
      setIsTransitioning(false);
    }, 300);
  };

  // Setup autoplay
  useEffect(() => {
    const startAutoplay = () => {
      clearInterval(autoplayIntervalRef.current);
      
      // Only start autoplay if not paused
      if (!isPaused && !isTransitioning) {
        autoplayIntervalRef.current = setInterval(() => {
          handleNext();
        }, 3000); // Auto scroll every 3 seconds
      }
    };
    
    startAutoplay();
    
    return () => {
      clearInterval(autoplayIntervalRef.current);
    };
  }, [isPaused, isTransitioning]);

  // Pause autoplay on hover
  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  // Resume autoplay on mouse leave
  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  // Handle indicator click
  const handleIndicatorClick = (index) => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    
    setTimeout(() => {
      setStartIndex(index);
      setIsTransitioning(false);
    }, 300);
  };

  return (
    <div className="container-fluid py-4" style={{ 
      paddingTop: '15px',
      paddingBottom: '25px'
    }}>
      <div className="heading mx-2">
        <h5 className="text-primary mt-4"><b>PRODUCTS</b></h5>
        <p style={{color:'var(--content_color)'}} className="ser-content mx-auto my-3 hcs hf ">
          Next-gen solutions to drive business growth
        </p>
      </div>

      <div 
        className="product-carousel-container position-relative py-3"
        ref={carouselRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="row px-4">
          {visibleProducts().map((product, index) => (
            <div 
              className="col-12 col-sm-6 col-md-3 p-3" 
              key={`card-${startIndex}-${index}`}
            >
              <ProductCard
                frontImage={product.image}
                title={product.title}
                description={product.description}
              />
            </div>
          ))}
        </div>
        
        <button 
          className="carousel-control-prev" 
          type="button" 
          onClick={handlePrev}
          disabled={isTransitioning}
          aria-label="Previous"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        </button>
        
        <button 
          className="carousel-control-next" 
          type="button" 
          onClick={handleNext}
          disabled={isTransitioning}
          aria-label="Next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
        </button>

        <div className="carousel-indicators">
          {products.map((_, index) => (
            <button
              key={index}
              type="button"
              className={startIndex === index ? "active" : ""}
              onClick={() => handleIndicatorClick(index)}
              aria-label={`Slide ${index + 1}`}
              disabled={isTransitioning}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductSection;