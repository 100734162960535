import React from 'react'
import Navbar from '../../components/Navbar'
import Hero from './Hero'
import HeroImg from '../../assets/img/TechnologyHero.png';
import BottomContent from '../../components/BottomContent';
import { Link } from 'react-router-dom';
import ScrollButton from "../../components/ScrollButton";
import Thank from '../../components/Thank';
import ContactButton from '../../components/ContactButton';
import DarkModeButton from '../../components/DarkModeButton';
import { FaCogs, FaCloud, FaMicrochip, FaCode, FaRobot, FaShieldAlt } from 'react-icons/fa';

export default function Technology() {
  const HeroImgTechnology = {
    heading: 'Technology & Engineering',
    subheading: 'Innovative Solutions for Modern Digital Challenges',
    text: `At Vedalytics, we deliver cutting-edge technology solutions to accelerate your digital transformation journey. From Agile lifecycle management and DevOps transformation to intelligent automation and application development, our technology expertise drives operational excellence and business agility.`,
    img: HeroImg,
    validate: false
  }
  const technologyServices = [
    {
      icon: <FaCogs size={32} />,
      heading: 'Agile Lifecycle & Lean Delivery',
      text: `Accelerate your development process with our Agile lifecycle and lean delivery practices. We help you implement Agile methodologies that foster collaboration, reduce lead times, and ensure continuous improvement. Our approach enhances efficiency and drives faster project deliveries.`,
      
    },
    {
      icon: <FaCloud size={32} />,
      heading: 'DevOps Transformation & Cloud Adoption',
      text: `Transform your IT operations with our DevOps practices, promoting automation, continuous integration, and delivery. We integrate cloud-native solutions and microservices architecture to enhance scalability and resilience, enabling seamless and agile software development.`,
      
    },
    {
      icon: <FaMicrochip size={32} />,
      heading: 'API Development & Data Services',
      text: `Build powerful, secure, and scalable APIs to enable seamless data exchange and integration. Our API development services enhance interoperability between systems, while our data practices ensure that your data is efficiently managed, processed, and analyzed for strategic insights.`,
      
    },
    {
      icon: <FaRobot size={32} />,
      heading: 'Intelligent Automation & QA Engineering',
      text: `Leverage the power of intelligent automation with RPA and CPA to optimize business processes. Our quality assurance and engineering services ensure high-performance applications, with rigorous testing and validation to maintain system integrity and reliability.`,
     
    },
    {
      icon: <FaCode size={32} />,
      heading: 'Application Development & Modern Technologies',
      text: `Stay ahead with modern application development using technologies like .NET, Java, Progressive Web Apps, Big Data, and GenAI. Our engineering team builds high-quality, robust applications to meet your evolving business needs.`,
   
    },
    {
      icon: <FaShieldAlt size={32} />,
      heading: 'Zero Maintenance & IT Support',
      text: `Leverage our Auto-Heal methodology to achieve zero maintenance, maintaining optimal application performance without manual effort. Our proactive IT support services detect and resolve issues ahead of time, ensuring uninterrupted business operations and maximum efficiency.`,
    }
  ];
  return (
    <>
      <Navbar />
      <Hero
        heading={HeroImgTechnology.heading}
        subheading={HeroImgTechnology.subheading}
        text={HeroImgTechnology.text}
        img={HeroImg}
        validate={HeroImgTechnology.validate}
      />
       {/* Services Section - Modern Card Layout */}
      <div className="container py-5">        
        <div className="row g-4">
          {technologyServices.map((service, index) => (
            <div className="col-md-6 mb-4" key={index} data-aos="fade-up" data-aos-delay={index * 100}>
              <div className="card h-100 border-0 shadow-sm">
                <div className="card-body p-4">
                  {/* <div className={`icon-circle bg-light bg-opacity-10 mb-4`} style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: `black`,
                  }}>
                    {service.icon}
                  </div> */}
                  <h3 className={`h4 mb-3 fw-bold`}>{service.heading}</h3>
                  <p className="text-muted mb-3">{service.text}</p>
                  <Link to="/scheduledemo" className="btn btn-sm btn-outline-primary">
                      Learn More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Call to Action Section - Improved */}
      <section className='' >
        <div className="p-5 text-center" style={{ backgroundColor: '#3BB893' }} data-aos="fade-up" data-aos-anchor-placement="top-center">
          <h3 className='text-light'>Having Projects ?</h3>
          <p className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
          <div className="button">
            <button className='btn'><Link style={{ textDecoration: 'none', color: 'green' }} to='/scheduledemo'>Schedule Demo</Link></button>
          </div>
        </div>
      </section>
      <Thank />
      <ContactButton />
      <DarkModeButton />
      <BottomContent/>
      <ScrollButton/>
      {/* Custom CSS */}
      <style>{`
        .card {
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          background: var(--card_color);
          color: var(--heading_color);
        }
        .card:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        }
      `}</style>
      
    </>
  )
}
