import React from 'react';
import Navbar from '../../components/Navbar';
import BottomContent from '../../components/BottomContent';
import Hero from './Hero';
import ScrollButton from "../../components/ScrollButton";
import ContactButton from '../../components/ContactButton';
import Thank from '../../components/Thank';
import DarkModeButton from '../../components/DarkModeButton';
import '../Industries/IndStyle.css'

export default function Client() {
    return (
        <>
            {/* Navbar Component */}
            <Navbar />

            {/* Hero Section */}
            <Hero
                title="Client Managed Engagement"
                des="Take full control of your projects while leveraging Vedalytics' expertise for execution, innovation, and scalability."
            />

            {/* Introduction Section */}
            <section className="client-introduction py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>You Lead, We Support</b></h5>
                    <p style={{color:"var(--heading_color)"}}>
                        The <b>Client Managed Engagement</b> model gives you complete authority over your projects, while Vedalytics provides expertise, development resources, and technical execution to ensure success.
                    </p>
                </div>
            </section>

            {/* Key Benefits Section */}
            <section className="client-benefits py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Why Choose Client Managed Engagement?</b></h5>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm text-center p-3">
                                <h5 className="fw-bold">Full Control</h5>
                                <p>You make all project decisions while we provide the expertise and execution.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm text-center p-3">
                                <h5 className="fw-bold">On-Demand Expertise</h5>
                                <p>Access experts anytime, no long-term commitments.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm text-center p-3">
                                <h5 className="fw-bold">Scalability</h5>
                                <p>Scale your projects efficiently with flexible engagement options.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Responsibilities Section */}
            <section className="client-responsibilities py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Who Does What?</b></h5>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">Your Responsibilities</h5>
                                <ul>
                                    <li>Define project goals and objectives.</li>
                                    <li>Manage internal workflows and decision-making.</li>
                                    <li>Provide domain expertise and business insights.</li>
                                    <li>Oversee final integration and deployment.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">Vedalytics' Role</h5>
                                <ul>
                                    <li>Provide development, data science, and technical execution.</li>
                                    <li>Offer strategic consulting and solution architecture.</li>
                                    <li>Develop, test, and optimize projects.</li>
                                    <li>Ensure smooth integration with your existing systems.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Engagement Workflow Section */}
            <section className="client-workflow py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>How It Works</b></h5>
                    <div className="row text-center">
                        <div className="col-md-3">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">1. Define Objectives</h5>
                                <p>You outline the project goals and requirements.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">2. AI Development</h5>
                                <p>Vedalytics builds and optimizes AI projects.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">3. Testing & Validation</h5>
                                <p>Ensures performance standards are met.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">4. Deployment</h5>
                                <p>AI solutions are integrated into your workflows.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Use Cases Section */}
            <section className="client-usecases py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Where This Model Works Best</b></h5>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>AI Research & Innovation:</strong> When internal teams need AI expertise to accelerate projects.</li>
                        <li className="list-group-item"><strong>Enterprise AI Integration:</strong> Large organizations managing AI solutions in-house but need expert execution.</li>
                        <li className="list-group-item"><strong>Scalable AI Development:</strong> Businesses requiring AI solutions but without the infrastructure to build them from scratch.</li>
                    </ul>
                </div>
            </section>

            {/* Closing Statement */}
            <Thank />

            {/* Bottom Content */}
            <BottomContent />

            {/* Dark Mode Button */}
            <DarkModeButton />

            {/* Contact Button */}
            <ContactButton />

            {/* Scroll to Top Button */}
            <ScrollButton />
        </>
    );
}
