import React from 'react';
import thanklogo from '../assets/logo/thanklogo.png';
import './Thank.css';
import { Link } from 'react-router-dom';

export default function Thank() {
    return (
        <>
            <section>
                <div 
                    className="container-fluid p-3 thank min-vw-100 d-flex align-items-center justify-content-center" 
                    data-aos="fade-up" 
                    data-aos-anchor-placement="top-center" 
                    style={{
                        height: "35vh", 
                        // background: 'linear-gradient(270deg, #001df5 0%, #c7cdd6 100%)'
                        background:'var(--hero_linear)'
                    }}
                >
                    <div className="row w-100">
                        {/* Logo Section */}
                        <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start align-items-center mb-3">
                            <img 
                                src={thanklogo} 
                                alt="Thank You Logo" 
                                className="mt-md-0" 
                                style={{ height: "75px" }}
                            />
                        </div>

                        {/* Button Section */}
                        <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-center">
                            {/* <a 
                                className="nav-link text-dark" 
                                href="/contact"
                            >
                                <button 
                                    type="button" 
                                    className="btn btn-outline-light"
                                >
                                    Contact Us
                                </button>
                            </a> */}
                            <Link to="/contact" className="nav-link text-dark">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary"
                                >
                                    Contact Us
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
