import React from 'react';
import Navbar from '../../components/Navbar';
import BottomContent from '../../components/BottomContent';
import Hero from './Hero'
import ScrollButton from "../../components/ScrollButton";
import ContactButton from '../../components/ContactButton';
import Thank from '../../components/Thank';
import DarkModeButton from '../../components/DarkModeButton';

export default function Shuraksha(){
    return(
        <>
        <Navbar/>
        <Hero
         title='Shuraksha'
         des='Something'
         />
         <Thank />
        <BottomContent/>
        <DarkModeButton />
        <ContactButton/>
        <ScrollButton />
        </>
    );
}