import React, { useState, useEffect } from "react";
import './DarkModeButton.css'

const DarkModeButton = () => {
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        // Check if there's a saved theme preference
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            setIsDark(true);
            document.querySelector('body').setAttribute('data-theme', 'dark');
        }
    }, []);
    

    const toggleTheme = () => {
        const newTheme = !isDark;
        setIsDark(newTheme);

        // Update the theme
        document.querySelector('body').setAttribute(
            'data-theme',
            newTheme ? 'dark' : 'light'
        );

        // Save the preference
        localStorage.setItem('theme', newTheme ? 'dark' : 'light');
    };

    return (
        <button onClick={toggleTheme}
        className="p-1 d-flex align-items-center justify-content-center darkbutton"
            aria-label={isDark ? 'Switch to light mode' : 'Switch to dark mode'}
            style={{ 
                // border: 'none', 
                // background: 'inherit', 
                outline: 'none'  // Ensure no outline appears
            }}>
                {isDark ? (
                <i className="bi bi-moon-fill text-light" title="Light Mode ?"></i> // Replace with your Moon icon
            ) : (
                <i className="bi bi-sun-fill text-warning" title="Dark Mode ?"></i> // Replace with your Sun icon
            )}
        
        </button>
    );
};

export default DarkModeButton;
