import './App.css';
import About from './routes/About';
import Contact from './routes/Contact';
import Home from './routes/Home';
import { Routes, Route } from 'react-router-dom'
import ScheduleDemo from './components/ScheduleDemo';
import Privacy from './components/Privacy';
import Terms from './components/Terms';

import Digital from './NavComponents/Services/Digital';
import Consulting from './NavComponents/Services/Consulting';
import Technology from './NavComponents/Services/Technology';
import DataAna from './NavComponents/Services/Technology';
import Cloud from './NavComponents/Services/Cloud';
import Intelligent from './NavComponents/Services/Intelligent';
import Product from './routes/Product';
import Service from './routes/Service';
import ScrollTooTop from "./components/ScrollTooTop";
import BfsiSystem from './NavComponents/Industries/BfsiSystem';
import EducationalSystem from './NavComponents/Industries/EducationalSystem';
import HealthCare from './NavComponents/Industries/HealthCare';
import Professional from './NavComponents/Industries/Professional';
import Client from './NavComponents/Models/Client';
import Co from './NavComponents/Models/Co';
import Ved from './NavComponents/Models/Ved';
import Guru from './NavComponents/Labs/Guru';
import Mantr from './NavComponents/Labs/Mantr';
import Shuraksha from './NavComponents/Labs/Shuraksha';
import Vidya from './NavComponents/Labs/Vidya';
import Innovation from './NavComponents/Labs/Innovation';

function App() {
  return (
    <div className="App">
      <ScrollTooTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path='/scheduleDemo' element={<ScheduleDemo />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />

        {/* <Route path='/appdev' element={<AppDev />} />
        <Route path='/quality' element={<Quality />} />
        <Route path='/devops' element={<DevOps />} />
        <Route path='/dataana' element={<DataAna />} />
       < Route path='/cloud' element={<Cloud/>}/>
       < Route path='/intel' element={<Intelligent/>}/> */}
        <Route path='/digital' element={<Digital />} />
        <Route path='/consulting' element={<Consulting />} />
        <Route path='/technology' element={<Technology />} />
       < Route path='/bfsi' element={<BfsiSystem/>}/>
       < Route path='/education' element={<EducationalSystem/>}/>
       < Route path='/healthcare' element={<HealthCare/>}/>
       < Route path='/professional' element={<Professional/>}/>
       < Route path='/client' element={<Client/>}/>
       < Route path='/co' element={<Co/>}/>
       < Route path='/ved' element={<Ved/>}/>
       < Route path='/guru' element={<Guru/>}/>
       < Route path='/mantr' element={<Mantr/>}/>
       < Route path='/suraksha' element={<Shuraksha/>}/>
       < Route path='/vidya' element={<Vidya/>}/>
       < Route path='/inovation' element={<Innovation/>}/>

      </Routes>
    </div>
  );
}

export default App;
