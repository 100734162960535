import React from 'react';
import './BottomContent.css';
import icon from '../assets/logo/veda.png'
import { Link } from 'react-router-dom'
import locIcon from '../assets/img/Group 273.svg'
import webIcon from '../assets/img/Group 274.svg'
import phoneIcon from '../assets/img/Group 331.svg'
import mailIcon from '../assets/img/Group 332.svg'
import canIcon from '../assets/img/canada.png'
import usaIcon from '../assets/img/usa.png'
import { MapPin } from "lucide-react";

// const locations = [
//     { name: "Delhi", link: "" },
//     { name: "Noida", link: "https://www.google.com/maps/place/Vedalytics+software+solutions/@28.6332208,77.2265091,11.6z/data=!4m10!1m2!2m1!1svedalytics+software+solutions+private+limited!3m6!1s0x390ce54929df6095:0xca68387e30f094ba!8m2!3d28.585129!4d77.313256!15sCi12ZWRhbHl0aWNzIHNvZnR3YXJlIHNvbHV0aW9ucyBwcml2YXRlIGxpbWl0ZWSSARBzb2Z0d2FyZV9jb21wYW554AEA!16s%2Fg%2F11wh5jtqt6?entry=ttu&g_ep=EgoyMDI1MDMyMy4wIKXMDSoASAFQAw%3D%3D" },
//     { name: "Hyderabad", link: "" },
//     { name: "Bangalore", link: "" },
// ];

export default function BottomContent() {
    return (
        <>
            <section  style={{ backgroundColor: 'var(--bottom_color)' }}>
                <div className="p-5 bottom" >
                    <div className="row"style={{color:'var(--heading_color)'}} >
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 p-0">
                            <img className="logo-bottom" src={icon} alt="logo" />
                            <p className="mt-3 f-heading"><b>Vedalytics Software Solutions Pvt. Ltd</b></p>
                            <div className="d-flex p-0 my-1">
                                <img src={locIcon} alt="g1" />
                                <p className="f-content pl-2 m-0">811, 8th Floor, Vijaya Building, 17, Barakhamba Road, Connaught Place, New
                                    Delhi – 110001.</p>
                            </div>
                            <div className="d-flex p-0 my-1">
                                <img src={webIcon} alt="g1" />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center">www.vedalytics.com</p>
                            </div>
                            <div className="d-flex p-0 my-1">
                                <img src={mailIcon} alt="g1" />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center"><a href="mailto:info@vedalytics.com"
                                    style={{ textDecoration: "none" }}>info@vedalytics.com</a></p>
                            </div>
                            <div className="d-flex p-0 my-1">
                                <img src={phoneIcon} alt="g1" />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center">+91-11-23739579</p>
                            </div>
                        </div>
                        <div className="pl-1 pl-sm-1 pl-md-5 pl-lg-5 pl-xl-5 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <p className="mt-5 f-heading"><b>Company</b></p>
                            <li className='f-content f-link' style={{ listStyle: 'none' }}><Link to='/about' style={{ color: 'var(--heading_color)' }} >About Us</Link></li>
                            <li className='f-content f-link' style={{ listStyle: 'none' }}><Link to='/contact' style={{ color: 'var(--heading_color)' }} >Contact Us</Link></li>
                            <li className='f-content f-link' style={{ listStyle: 'none' }}><Link to='/privacy' style={{ color: 'var(--heading_color)' }} >Privacy Policy</Link></li>
                            <li className='f-content f-link' style={{ listStyle: 'none' }}><Link to='/terms' style={{ color: 'var(--heading_color)' }} >Terms & Conditions</Link></li>
                        </div>
                        {/* <div className="pl-1 pl-sm-1 pl-md-5 pl-lg-5 pl-xl-5 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <p className="mt-5"><b>India Offices</b></p>
                            <li className='f-content' style={{ listStyle: 'none' }}> Delhi </li>
                            <li className='f-content' style={{ listStyle: 'none' }}> Noida </li>
                            <li className='f-content' style={{ listStyle: 'none' }}> Hyderabad </li>
                            <li className='f-content' style={{ listStyle: 'none' }}>Bangalore</li>
                        </div> */}
                        <div className="pl-1 pl-sm-1 pl-md-5 pl-lg-5 pl-xl-5 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <p className="mt-5 f-heading"><b>India Offices</b></p>
                            <ul className="list-unstyled">                                
                                    <li  className="f-content my-2 d-flex align-items-center text-decoration-none">
                                        {/* <a
                                            href=""
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-flex align-items-center text-decoration-none"
                                            style={{color: 'var(--content_color)',listStyle:'none'}}
                                        > */}
                                            <MapPin className="mr-2 text-blue-500" size={16} />
                                            Delhi
                                        {/* </a> */}
                                    </li>
                                
                            </ul>
                            <ul className="list-unstyled">
                                
                                    <li  className="f-content my-2">
                                        <a
                                            href="https://www.google.com/maps/place/Vedalytics+software+solutions/@28.6332208,77.2265091,11.6z/data=!4m10!1m2!2m1!1svedalytics+software+solutions+private+limited!3m6!1s0x390ce54929df6095:0xca68387e30f094ba!8m2!3d28.585129!4d77.313256!15sCi12ZWRhbHl0aWNzIHNvZnR3YXJlIHNvbHV0aW9ucyBwcml2YXRlIGxpbWl0ZWSSARBzb2Z0d2FyZV9jb21wYW554AEA!16s%2Fg%2F11wh5jtqt6?entry=ttu&g_ep=EgoyMDI1MDMyMy4wIKXMDSoASAFQAw%3D%3D"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-flex align-items-center text-decoration-none"
                                            style={{color: 'var(--content_color)',listStyle:'none'}}
                                        >
                                            <MapPin className="mr-2 text-blue-500" size={16} />
                                            Noida
                                        </a>
                                    </li>
                                
                            </ul>
                            <ul className="list-unstyled">
                               
                                    <li  className="f-content my-2 d-flex align-items-center text-decoration-none">
                                        {/* <a
                                            href=""
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-flex align-items-center text-decoration-none"
                                            style={{color: 'var(--content_color)',listStyle:'none'}}
                                        > */}
                                            <MapPin className="mr-2 text-blue-500" size={16} />
                                            Hyderabad
                                        {/* </a> */}
                                    </li>
                            </ul>
                            <ul className="list-unstyled">
                               
                                    <li className="f-content my-2 d-flex align-items-center text-decoration-none">
                                        {/* <a
                                            href=""
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="d-flex align-items-center text-decoration-none"
                                            style={{color: 'var(--content_color)',listStyle:'none'}}
                                        > */}
                                            <MapPin className="mr-2 text-blue-500" size={16} />
                                            Bangalore
                                        {/* </a> */}
                                    </li>
                                
                            </ul>
                        </div>
                        <div className="pl-1 pl-sm-1 pl-md-5 pl-lg-5 pl-xl-5 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                            <p className="mt-5 f-heading"><b>Worldwide Footprints</b></p>
                            <div className="phone d-flex p-0 my-1">
                                <img src={canIcon} alt="icon" style={{ width: '45px' }} />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center">Canada (Ontario)</p>
                            </div>
                            <div className="phone d-flex p-0 my-1">
                                <img src={usaIcon} alt="icon" style={{ width: '45px' }} />
                                <p className="f-content pl-2 m-0 justify-content-center align-self-center">USA (Las Vegas)</p>
                            </div>
                            <div className='pt-5'>
                                <span className="mt-2 f-heading">Follow Us..</span>
                                <div className="d-flex pt-1 my-2 gap-3">
                                    <a href="https://www.facebook.com/Vedalytics-Software-Solutions-Pvt-Ltd-100287189409351" target="_blank" rel="noopener noreferrer" className="social">
                                    <i className="fab fa-facebook mr-2" style={{ fontSize: '30px', color: '#1877F2' }}></i>
                                    </a>
                                    <a href="https://twitter.com/vedalytics" target="_blank" rel="noopener noreferrer" className="social">
                                    <i className="fab fa-x-twitter mr-2" style={{ fontSize: '30px', color: '#000000' }}></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/vedalytics-solutions/mycompany/" target="_blank" rel="noopener noreferrer" className="social">
                                    <i className="fab fa-linkedin" style={{ fontSize: '30px', color: '#0A66C2' }}></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                          
                        </div>
                    </div>
                </div>
                <div className="p-4 text-center bg-dark copy" style={{ width: '100%',color:'white'}}>
                    © Copyright 2025. Vedalytics. All rights reserved.
                </div>
            </section>
            
        </>
    )
}

