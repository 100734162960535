import React from "react";
import { Link } from 'react-router-dom';
import Navbar from "../components/Navbar";
import BottomContent from "../components/BottomContent";
import ScrollButton from "../components/ScrollButton";
import Thank from "../components/Thank";
import DarkModeButton from "../components/DarkModeButton";
import ContactButton from "../components/ContactButton";
import "./Product.css";

export default function Product() {
  // Product Data - Alternating Layout
  const products = [
    {
      imageURL: "media/img/VedaqgptLogo.png",
      productName : "VedaQGPT",
      productDescription : "VedaQGPT is a local Generative AI solution designed to deliver secure and adaptive data intelligence without external exposure. It leverages the contextual power of RAG and the autonomy of Agentic AI to optimize operations, enhance customer engagement, and drive innovation while maintaining complete data privacy.",
      link: "/scheduledemo",
      point: "Local Generative AI solution for secure data intelligence and operational efficiency.",
    },
    {
      imageURL: "media/img/MantraiLogo.png",
      productName: "MantrAI",
      productDescription: "MantrAI is an AI-powered developer tool designed to accelerate coding and streamline the development process. It assists developers with intelligent code generation, real-time suggestions, debugging support, and best practice recommendations, enhancing productivity and efficiency in software development.",
      link: "/scheduledemo",
      point: "AI-powered coding assistant for code generation, debugging, and development workflow optimization.",
    },
    {
      imageURL: "media/img/SandboxLogo.png",
      productName: "LLM Sandbox",
      productDescription: "LLM Sandbox is a specialized platform designed to enhance engineering productivity by providing a customized environment for building, testing, and deploying LLM-driven solutions. It empowers developers with the flexibility to experiment, prototype, and optimize models tailored to organizational needs, ensuring efficient integration and iteration.",
      link: "/scheduledemo",
      point: "Customizable platform for developing, testing, and deploying LLM-based solutions.",
    },
    {
      imageURL: "media/img/GuruLogo.png",
      productName: "Guru",
      productDescription: "Guru is an advanced AI-powered chatbot designed to enhance customer engagement and support. With voice and text-based interactions, it provides intelligent responses, automates queries, and seamlessly integrates with various platforms, ensuring an efficient and personalized user experience.",
      link: "/scheduledemo",
      point: "AI-powered chatbot with voice & text-based interactions, intelligent automation, and seamless integration.",
    },
    {
      imageURL: "media/img/VidyaLogo.png",
      productName: "Vidya",
      productDescription: "Vidya revolutionizes education with AI-driven insights, personalized learning paths, and intelligent tutoring. It enhances student engagement, automates assessments, and provides educators with deep analytics to optimize teaching strategies and improve learning outcomes.",
      link: "/scheduledemo",
      point: "AI-powered education system with personalized learning, intelligent tutoring, and automated assessments.",
    },
    {
      imageURL: "media/img/SurakshaLogo.png",
      productName: "Suraksha",
      productDescription: "Suraksha is an advanced AI-powered security solution engineered to protect applications, data, and digital infrastructure. It features cutting-edge authentication, real-time threat monitoring, and smooth integration with existing security setups to deliver complete protection against cyber risks.",
      link: "/scheduledemo",
      point: "AI-powered security solution with robust authentication, real-time threat monitoring, and effortless integration.",
    }
    // {
    //   imageURL: "media/img/c2.png",
    //   productName: "Prabhamandal",
    //   productDescription: "Suraksha is a robust AI-powered security framework designed to safeguard applications, data, and digital infrastructure. It offers advanced authentication, real-time threat detection, and seamless integration with existing security systems to ensure comprehensive protection against cyber threats.",
    //   link: "/scheduledemo",
    //   point: "AI-driven security framework with advanced authentication, threat detection, and seamless integration.",
    // },


    
  ];

  return (
    <>
      <Navbar />
      {/* Hero Section */}
      <div className="mt-5" style={{ background: "var(--hero_linear)" }}>
        <div className="container text-center border-bottom border-top">
          <h1 className="mt-5">
            <b style={{color:'var(--content_color)'}}>Our Products</b>
          </h1>
          <h3 className="mb-5" style={{color:'var(--content_color)'}}>
            Take a look at some of our Ready to use Developed Products.
          </h3>
        </div>
      </div>

      {/* Product Sections - Alternating Layout */}
      <div className="container mt-5 mb-5">
        {products.map((product, index) => (
          <div className="row mb-5 align-items-center" key={index}>
            {index % 2 === 0 ? (
              <>
                <div className="col-12 col-md-6 d-flex justify-content-center order-md-1 order-2 ">
                  <img src={product.imageURL} alt={product.productName} style={{ width: "250px", height: "250px" , borderRadius:"30px"}} />
                </div>
                <div className="col-12 col-md-6 text-center text-md-start order-md-2 order-1">
                  <h1 style={{ color: "var(--heading_color)" }}>{product.productName}</h1>
                  <h6 style={{ color: "var(--heading_color)" }}>{product.point}</h6>
                  <p className="fs-5 text-muted">{product.productDescription}</p>
                  <Link to={product.link} className="fs-5 text-decoration-none btn btn-outline-primary">
                      Learn More <i className="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="col-12 col-md-6 text-center text-md-start">
                  <h1 style={{ color: "var(--heading_color)" }}>{product.productName}</h1>
                  <h6 style={{ color: "var(--heading_color)" }}>{product.point}</h6>
                  <p className="fs-5 text-muted">{product.productDescription}</p>
                  <a href={product.link} className="fs-5 text-decoration-none btn btn-outline-primary">
                    Learn More <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center order-first order-md-last">
                  <img src={product.imageURL} alt={product.productName} style={{ width: "250px", height: "250px", borderRadius:"30px" }} />
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {/* Footer Components */}
      <Thank />
      <BottomContent />
      <ContactButton />
      <DarkModeButton />
      <ScrollButton />
    </>
  );
}
