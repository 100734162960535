// import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import Hero1 from '../assets/HeroImg/HeroImg1.png'
// import Hero2 from '../assets/HeroImg/HeroImg2.png'
// import Hero3 from '../assets/HeroImg/HeroImg3.png'
// import './HeroImg.css'

// export default function HeroImg() {
//     const [activeSlide, setActiveSlide] = useState(0);
//     const totalSlides = 3;

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
//         }, 5000); // Change slide every 5 seconds

//         return () => clearInterval(interval);
//     }, []);

//     // Custom carousel indicator component
//     const CarouselIndicator = ({ index, active }) => {
//         return (
//             <li 
//                 data-target="#carouselExampleIndicators" 
//                 data-slide-to={index} 
//                 className={`carousel-indicator ${active ? 'active' : ''}`}
//             >
//                 <div className="indicator-fill" style={{
//                     animationDuration: '5s',
//                     animationPlayState: active ? 'running' : 'paused'
//                 }}></div>
//             </li>
//         );
//     };

//     return (
//         <>
//             <section>
//                 <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
//                     {/* Custom Indicators */}
//                     <ol className="carousel-indicators custom-indicators">
//                         <CarouselIndicator index={0} active={activeSlide === 0} />
//                         <CarouselIndicator index={1} active={activeSlide === 1} />
//                         <CarouselIndicator index={2} active={activeSlide === 2} />
//                     </ol>

//                     <div 
//                         className="carousel-inner" 
//                         style={{
//                             background: 'linear-gradient(270deg, #000000 0%, #556261 100%)',
//                             backgroundSize: 'cover',
//                             backgroundPosition: 'center',
//                             backgroundRepeat: 'no-repeat',
//                             height: '90vh',
//                             width: '100%',
//                         }}
//                     >
//                         {/* First Slide */}
//                         <div className={`carousel-item ${activeSlide === 0 ? 'active' : ''}`}>
//                             <div className="main-area mt-5 p-4">
//                                 <div className="row mx-auto container my-5">
//                                     <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-3 mt-sm-3 mt-md-5 mt-lg-5 mt-xl-5">
//                                         <h3 className="f-color-light px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1 text-primary">Delivering a personalized, Client-Centric Digital Experiences with</h3>
//                                         <h3 className="head-main px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-warning hf">Emerging and Disruptive Technology.</h3>
//                                         <p className="f-color-light px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-light">At Vedalytics, we leverage emerging and disruptive technologies to create personalized, impactful digital experiences. Through our advanced R&D labs, we're pioneering tools that drive business success and contribute to a better, more connected world.</p>
//                                         <span className="px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1">
//                                             <button className='btn btn-danger text-primary'><Link to='/scheduleDemo' style={{ textDecoration: 'none', color: 'white' }}>Know More</Link></button>
//                                         </span>
//                                     </div>
//                                     <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block justify-content-center align-self-center col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12" style={{ height: '60vh' }}>
//                                         <img className="bg-image img-fluid" src={Hero1} alt="bg" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Second Slide */}
//                         <div className={`carousel-item ${activeSlide === 1 ? 'active' : ''}`}>
//                             <div className="main-area mt-5 p-4">
//                                 <div className="row mx-auto container my-5">
//                                     <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-3 mt-sm-3 mt-md-5 mt-lg-5 mt-xl-5">
//                                         <h3 className="f-color-light px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1 text-primary">MantrAI AI-Powered Developer Tools</h3>
//                                         <h3 className="head-main px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-warning hf">Emerging and Disruptive Technology.</h3>
//                                         <p className="f-color-light px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-light">At Vedalytics, we leverage emerging and disruptive technologies to create personalized, impactful digital experiences. Through our advanced R&D labs, we're pioneering tools that drive business success and contribute to a better, more connected world.</p>
//                                         <span className="px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1">
//                                             <button className='btn btn-danger text-primary'><Link to='/scheduleDemo' style={{ textDecoration: 'none', color: 'white' }}>Schedule a Demo</Link></button>
//                                         </span>
//                                     </div>
//                                     <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block justify-content-center align-self-center col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12" style={{ height: '60vh' }}>
//                                         <img className="bg-image img-fluid" src={Hero2} alt="bg" style={{ width: '50%', height: '100%', objectFit: 'cover', marginLeft: '120px' }} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Third Slide */}
//                         <div className={`carousel-item ${activeSlide === 2 ? 'active' : ''}`}>
//                             <div className="main-area mt-5 p-4">
//                                 <div className="row mx-auto container my-5">
//                                     <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-3 mt-sm-3 mt-md-5 mt-lg-5 mt-xl-5">
//                                         <h3 className="f-color-light px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1 text-primary">Vedalytics Corporate and Industrial Advance training for</h3>
//                                         <h3 className="head-main px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-warning hf">Fresher and IT professionals</h3>
//                                         <p className="f-color-light px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-light">We provide specialized IT training for freshers and professionals, covering AI, Data Science, Machine Learning, NLP, Cloud Computing, DevOps, Python, and more. Gain skills to thrive in today's tech-driven world.</p>
//                                         <span className="px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1">
//                                             <button className='btn btn-danger text-primary'><Link to='/scheduleDemo' style={{ textDecoration: 'none', color: 'white' }}>Learn More</Link></button>
//                                         </span>
//                                     </div>
//                                     <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block justify-content-center align-self-center col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12" style={{ height: '60vh' }}>
//                                         <img className="bg-image img-fluid ml-5" src={Hero3} alt="bg" style={{ width: '90%', height: '110%', objectFit: 'cover', marginLeft: '120px' }} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     {/* Navigation Controls */}
//                     <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
//                         <span className="carousel-control-prev-icon mr-5" aria-hidden="true" />
//                         <span className="sr-only">Previous</span>
//                     </a>
//                     <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
//                         <span className="carousel-control-next-icon ml-5" aria-hidden="true" />
//                         <span className="sr-only">Next</span>
//                     </a>
//                 </div>
//             </section>
//         </>
//     )
// }

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Hero1 from '../assets/HeroImg/HeroImg1.png';
import Hero2 from '../assets/HeroImg/HeroImg2.png';
import Hero3 from '../assets/HeroImg/HeroImg3.png';
import Hero4 from '../assets/HeroImg/HeroImg4.png';
import './HeroImg.css';

export default function HeroImg() {
    const [activeSlide, setActiveSlide] = useState(0);
    const totalSlides = 4;

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const CarouselIndicator = ({ index, active }) => (
        <li 
            data-target="#carouselExampleIndicators" 
            data-slide-to={index} 
            className={`carousel-indicator ${active ? 'active' : ''}`}
        >
            <div className="indicator-fill" style={{
                animationDuration: '5s',
                animationPlayState: active ? 'running' : 'paused'
            }}></div>
        </li>
    );

    const slides = [
        {
            title: "Delivering a personalized, Client-Centric Digital Experiences with",
            subtitle: "Emerging and Disruptive Technology.",
            description: "Vedalytics leverages emerging and disruptive technologies to create personalized, impactful digital experiences, pioneering innovative tools through advanced R&D to drive business success and build a more connected world",
            buttonText: "Know More",
            image: Hero1,
            imageStyle: { width: '100%', objectFit: 'cover', marginLeft:'10%' }
        },
        {
            title: "MantrAI AI-Powered Developer Tools",
            subtitle: "Boosting productivity, reducing errors & accelerating project timelines",
            description: "MantrAI is designed to serve as an intelligent and user-friendly platform for developers of all experience levels, from beginners seeking educational support to professionals looking to improve efficiency and precision in their workflows.",
            buttonText: "Schedule a Demo",
            image: Hero2,
            imageStyle: { width: '100%', objectFit: 'cover', marginLeft: '10%' }
        },
        {
            title: "VedaQGPT - A local LLM/SLM solution",
            subtitle: "Designed to deliver Generative AI capabilities using your data",
            description: " By integrating the contextual depth of RAG with the autonomy of Agentic AI, this solution transforms how organizations interact with data, streamline operations & enhance customer engagement driving innovation & fostering sustainable growth in today’s digital-first landscape.",
            buttonText: "Schedule a Demo",
            image: Hero3,
            imageStyle: { width: '100%',objectFit: 'cover',marginLeft:'10%'}
        },
        {
            title: "Vedalytics Corporate and Industrial Advance training for",
            subtitle: "Fresher and IT professionals",
            description: "We provide specialized IT training for freshers and professionals, covering AI, Data Science, Machine Learning, NLP, Cloud Computing, DevOps, Python, and more. Gain skills to thrive in today's tech-driven world.",
            buttonText: "Learn More",
            image: Hero4,
            imageStyle: { width: '100%', objectFit: 'cover', marginLeft: '10%' ,transform: 'scaleX(-1)' }
        }
    ];

    return (
        <section className="hero-section" style={{ marginTop: '66px' }}> {/* Add margin-top for sticky navbar */}
            <div 
                id="carouselExampleIndicators" 
                className="carousel slide" 
                style={{
                    background: 'var(--hero_linear)',
                    // minHeight: '85vh',
                    height:'85vh',
                    position: 'relative',
                }}
            >
                <ol className="custom-indicators">
                    {slides.map((_, index) => (
                        <CarouselIndicator 
                            key={index} 
                            index={index} 
                            active={activeSlide === index} 
                        />
                    ))}
                </ol>

                <div className="carousel-inner h-100">
                    {slides.map((slide, index) => (
                        <div 
                            key={index}
                            className={`carousel-item ${activeSlide === index ? 'active' : ''} h-100`}
                        >
                            <div className="container h-100">
                                <div className="row h-100 align-items-center py-4 py-md-5">
                                    <div className="col-12 col-lg-6 order-2 order-lg-1 mb-4 mb-lg-0">
                                        <div className="px-3 px-md-0">
                                            <h3 className="text-primary fw-bold mb-2 fs-4 fs-md-3">
                                                {slide.title}
                                            </h3>
                                            <h3 className="text-warning fw-bold mb-3 fs-4 fs-md-3">
                                                {slide.subtitle}
                                            </h3>
                                            <p style={{color:'var(--content_color)'}} className="mb-4">
                                                {slide.description}
                                            </p>
                                            <Link 
                                                to="/scheduleDemo" 
                                                className="btn btn-outline-danger "
                                            >
                                                {slide.buttonText}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                                        <div className="image-container" style={{ height: '60vh' }}>
                                            <img 
                                                src={slide.image} 
                                                alt={`Slide ${index + 1}`}
                                                className="img-fluid h-100"
                                                style={slide.imageStyle}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Navigation arrows */}
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon mr-5" aria-hidden="true" />
                        <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon ml-5" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                </a>
                
            </div>
        </section>
    );
}