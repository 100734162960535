import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo/veda.png'
import "./Navbar.css";

export default function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top custom-navbar">
            {/* <div className="container"> */}
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt='logo' className="navbar-logo" />
                </Link>
                
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" role="button">
                                Industries
                            </span>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item" to="/education">Education System</Link>
                                <Link className="dropdown-item" to="/healthcare">Healthcare System</Link>
                                <Link className="dropdown-item" to="/bfsi">BFSI System</Link>
                                <Link className="dropdown-item" to="/professional">Professional Services</Link>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" role="button" >
                                Services
                            </span>
                            <div className="dropdown-menu mega-menu">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h6 className="dropdown-header">Vedalytics Lab & R&D</h6>
                                        <Link className="dropdown-item" to="/service">Our Services</Link>
                                        {/* <Link className="dropdown-item" to="/inovation">Innovation Lab</Link> */}
                                        {/* <Link className="dropdown-item" to="/mantr">MantrAI - Coding Solution</Link>
                                        <Link className="dropdown-item" to="/guru">Guru - Chatbot Solution</Link>
                                        <Link className="dropdown-item" to="/vidya">Vidya - AI Solution</Link>
                                        <Link className="dropdown-item" to="/suraksha">Suraksha -Safety Framework</Link> */}
                                    </div>
                                    <div className="col-md-4">
                                        <h6 className="dropdown-header">IT and Business Services</h6>
                                        <Link className="dropdown-item" to="/digital">Digital</Link>
                                        <Link className="dropdown-item" to="/consulting">Advisory & Consulting</Link>
                                        <Link className="dropdown-item" to="/technology">Technology</Link>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <h6 className="dropdown-header">IT and Business Services</h6>
                                        <Link className="dropdown-item" to="/appdev">Application Development</Link>
                                        <Link className="dropdown-item" to="/quality">Quality Assurance</Link>
                                        <Link className="dropdown-item" to="/devops">DevOps Services</Link>
                                        <Link className="dropdown-item" to="/cloud">Cloud Automation</Link>
                                        <Link className="dropdown-item" to="/intel">Intelligent Automation</Link>
                                        <Link className="dropdown-item" to="/dataana">Data & Analytics</Link>
                                    </div> */}
                                    
                                    <div className="col-md-4">
                                        <h6 className="dropdown-header">Engagement Model</h6>
                                        <Link className="dropdown-item" to="/client">Client Managed</Link>
                                        <Link className="dropdown-item" to="/co">Co-Managed</Link>
                                        <Link className="dropdown-item" to="/ved">Vedalytics Managed</Link>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/product">Products</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About Us</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            {/* </div> */}
        </nav>
    );
}