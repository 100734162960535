import React from 'react';
import Navbar from '../../components/Navbar';
import Hero from './Hero';
import HeroImg from '../../assets/img/ConsultingHero.png';
import { Link } from "react-router-dom"
import BottomContent from '../../components/BottomContent';
import ScrollButton from "../../components/ScrollButton";
import Thank from '../../components/Thank';
import ContactButton from '../../components/ContactButton';
import DarkModeButton from '../../components/DarkModeButton';
import { FaBuilding, FaUserTie, FaDatabase, FaRedo, FaLightbulb, FaIndustry } from 'react-icons/fa';

export default function Consulting() {
  const HeroImgConsulting = {
    heading: 'Advisory & Consulting',
    subheading: 'Empowering Transformation with Strategic Insights',
    text: `At Vedalytics, our Advisory & Consulting services are designed to guide businesses through digital transformation and innovation. From enterprise strategy and architecture to client experience and program management, we offer comprehensive solutions to address complex business challenges across various sectors, including education, healthcare, BFSI, industrial automation, and gaming.`,
    img: HeroImg,
    validate: false
  }

  const consultingServices = [
    {
      icon: <FaBuilding size={32} />,
      heading: 'Enterprise Strategy, Architecture & Transformation',
      text: `Drive your organization towards success with our comprehensive strategy and transformation services. We design and implement robust enterprise architectures that align with your business goals, ensuring seamless digital transformation and operational efficiency.`,
     
    },
    {
      icon: <FaUserTie size={32} />,
      heading: 'Client Experience and Lifecycle Management',
      text: `Enhance customer satisfaction with our client experience and lifecycle management solutions. We help you build long-lasting relationships through personalized interactions, optimized customer journeys, and data-driven insights that foster loyalty and engagement.`,
      
    },
    {
      icon: <FaDatabase size={32} />,
      heading: 'Data Science, Engineering and Governance',
      text: `Leverage the full potential of your data with our data science, engineering, and governance practices. From building data pipelines and integrating analytics to establishing data governance frameworks, we ensure data accuracy, compliance, and actionable insights.`,
     
    },
    {
      icon: <FaRedo size={32} />,
      heading: 'App Rationalization & Modernization',
      text: `Modernize your application portfolio with our rationalization and modernization services. We assess your existing applications, identify optimization opportunities, and upgrade them to align with modern technologies and best practices, reducing maintenance costs and increasing efficiency.`,
     
    },
    {
      icon: <FaLightbulb size={32} />,
      heading: 'Innovation and Program Management',
      text: `Navigate innovation with strategic program management services. Our experts plan, execute, and manage initiatives that foster innovation and drive growth while ensuring that your projects stay on track and within budget.`,
     
    },
    {
      icon: <FaIndustry size={32} />,
      heading: 'Business Sectors Expertise',
      text: `We have deep expertise across various business sectors, including Education, Healthcare, BFSI, Industrial Automation, and Gaming. Our consulting services are tailored to address industry-specific challenges and deliver impactful results.`,
      
    }
  ];
  return (
    <>
      <Navbar />
      <Hero
        heading={HeroImgConsulting.heading}
        subheading={HeroImgConsulting.subheading}
        text={HeroImgConsulting.text}
        img={HeroImg}
        validate={HeroImgConsulting.validate}
      />
      {/* Services Section - Modern Card Layout */}
      <div className="container py-5">        
        <div className="row g-4">
          {consultingServices.map((service, index) => (
            <div className="col-md-6 mb-4" key={index} data-aos="fade-up" data-aos-delay={index * 100}>
              <div className="card h-100 border-0 shadow-sm">
                <div className="card-body p-4">
                  {/* <div className={`icon-circle bg-light bg-opacity-10 mb-4`} style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: `black`,
                  }}>
                    {service.icon}
                  </div> */}
                  <h3 className={`h4 mb-3 fw-bold`}>{service.heading}</h3>
                  <p className="text-muted mb-3">{service.text}</p>
                  <Link to="/scheduledemo" className="btn btn-sm btn-outline-primary">
                      Learn More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Call to Action Section - Improved */}
      <section className='' >
        <div className="p-5 text-center" style={{ backgroundColor: '#3BB893' }} data-aos="fade-up" data-aos-anchor-placement="top-center">
          <h3 className='text-light'>Having Projects ?</h3>
          <p className='text-light'>Schedule a Free Demo and Start Business you always Dream.</p>
          <div className="button">
            <button className='btn'><Link style={{ textDecoration: 'none', color: 'green' }} to='/scheduledemo'>Schedule Demo</Link></button>
          </div>
        </div>
      </section>
      <Thank />
      <ContactButton />
      <BottomContent />
      <DarkModeButton />
      <ScrollButton/>
      {/* Custom CSS */}
      <style>{`
        .card {
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          background: var(--card_color);
          color: var(--heading_color);
        }
        .card:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        }
      `}</style>
      
    </>
  )
}
