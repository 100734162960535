// import React from 'react'
// import appdev from '../assets/img/Appdevimg.png'
// import data from '../assets/img/Dataimg.png'
// import devops from '../assets/img/DevOpsimg.png'
// import quality from '../assets/img/QA.png'
// import { Link } from 'react-router-dom'
// import "./ServiceSection.css";

// function ServiceSection() {
//   return (
//     <div className="container-fluid pt-4 sectionstyle" data-aos="fade-up" data-aos-anchor-placement="top-center">
//       <div className="heading mx-2" data-aos="fade-up" data-aos-anchor-placement="top-center">
//         <h5 className="text-primary mt-4"><b>SERVICES</b></h5>
//         <p className="ser-content mx-auto my-3 hcs hf">
//           Transform your business with
//           <span> 
//             <b style={{ color: "crimson" }}> Ved</b>
//             <b style={{ color: "darkorange" }}>al</b>
//             <b style={{ color: "forestgreen" }}>yt</b>
//             <b style={{ color: "dodgerblue" }}>ics</b>
//           </span>
//         </p>
//       </div>
//       <div className="contents row p-4">
//         {/** Common style for cards */}
//         {[
//           { to: '/appdev', src: appdev, title: 'Application Development', text: 'We offer project services of Android and iOS Development.' },
//           { to: '/quality', src: quality, title: 'Quality Assurance', text: 'QA Testing, Quality Assurance, and Digital Transformation.' },
//           { to: '/automation', src: devops, title: 'Automation & DevOps', text: 'We offer services in DevOps, RPA, and Machine Learning.' },
//           { to: '/dataana', src: data, title: 'Data & Analytics', text: 'Business Analytics Solutions to establish businesses.' }
//         ].map((service, index) => (
//           <div className="service col-12 col-sm-6 col-md-3 mb-4" data-aos="fade-up" data-aos-anchor-placement="top-center" key={index}>
//             <Link to={service.to} style={{ textDecoration: "none", color: 'black' }}>
//               <div className="card border-0 rad h-100" style={{height:'auto', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
//                 <img className="card-img-top img-fluid p-1" src={service.src} alt={service.title} style={{height:'230px',objectFit: 'cover', margin: '0 auto' }} />
//                 <div className="card-body text-center">
//                   <h5 className="card-title">{service.title}</h5>
//                   <p className="card-text text-secondary" style={{ fontSize: '0.9rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//                     {service.text}
//                   </p>
//                 </div>
//               </div>
//             </Link>
//           </div>
//         ))}
//       </div>
//     </div>
//   )
// }

// export default ServiceSection


import React from 'react';
import Service1 from '../assets/img/DigitalService.jpg';
import Service2 from '../assets/img/ConsultingService.jpg';
import Service3 from '../assets/img/TechnologyService.jpg';
import { Link } from 'react-router-dom';
import "./ServiceSection.css";

function ServiceSection() {
  return (
    <div className="container-fluid pt-4 sectionstyle" data-aos="fade-up" data-aos-anchor-placement="top-center">
      <div className="heading mx-2" data-aos="fade-up" data-aos-anchor-placement="top-center">
        <h5 className="text-primary mt-4"><b>SERVICES</b></h5>
        <p className="ser-content mx-auto my-3 hcs hf">
          Transform your business with Us
          {/* <span> 
            <b style={{ color: "crimson" }}> Ved</b>
            <b style={{ color: "darkorange" }}>al</b>
            <b style={{ color: "forestgreen" }}>yt</b>
            <b style={{ color: "dodgerblue" }}>ics</b>
          </span> */}
        </p>
      </div>
      
      <div className="row g-4 p-4">
        {[
          { to: '/digital', src: Service1, title: 'Digital', text: 'Innovative solutions for digital transformation, application development, and emerging technologies.' },
          { to: '/consulting', src: Service2, title: 'Advisory & Consulting', text: 'Strategic advisory for business transformation, innovation, and sector-specific solutions.' },
          { to: '/technology', src: Service3, title: 'Technology', text: 'Advanced engineering with DevOps, cloud adoption, automation, and modern application development.' },
          
        ].map((service, index) => (
          <div 
            className="col-12 col-sm-6 col-md-4 mb-4"
            data-aos="fade-up"
            data-aos-delay={index * 100}
            data-aos-anchor-placement="top-center"
            key={index}
          >
            <Link to={service.to} style={{ textDecoration: "none" }}>
              <div className="card border-0 shadow h-100 position-relative overflow-hidden" style={{ borderRadius: '12px' }}>
                <img
                  className="card-img"
                  src={service.src}
                  alt={service.title}
                  style={{ height: '280px', objectFit: 'cover' }}
                />
                <div className="card-img-overlay d-flex flex-column justify-content-end" style={{ 
                  background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.1) 100%)',
                  padding: '1.5rem'
                }}>
                  <h4 className="card-title text-white mb-2 fw-bold">{service.title}</h4>
                  <p className="card-text text-white opacity-75" style={{ fontSize: '0.94rem' }}>
                    {service.text}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServiceSection;