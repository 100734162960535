import React from 'react';
import Navbar from '../../components/Navbar';
import BottomContent from '../../components/BottomContent';
import Hero from './Hero';
import ScrollButton from "../../components/ScrollButton";
import ContactButton from '../../components/ContactButton';
import Thank from '../../components/Thank';
import DarkModeButton from '../../components/DarkModeButton';
import './IndStyle.css';

export default function Profession() {
    return (
        <>
            {/* Navbar Component */}
            <Navbar />

            {/* Hero Section */}
            <Hero
                title='Professional Services'
                des='Cutting-edge solutions designed to boost productivity, streamline processes, and drive professional growth.'
            />

            {/* Introduction Section */}
            <section className="profession-introduction py-5 ">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Empowering Professionals with Smart Solutions</b></h5>
                    <p className="" style={{color:"var(--heading_color)"}}>
                        At Vedalytics, we offer professional services designed to elevate productivity, streamline operations, and drive success. Our innovative solutions cater to various industries, helping businesses and professionals thrive in a competitive environment.
                    </p>
                </div>
            </section>

            {/* Key Services for Professionals */}
            <section className="key-services py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Our Key Professional Services</b></h5>
                    <p className="mb-4" style={{color:"var(--heading_color)"}}>
                        We offer specialized services for professionals across industries to enhance efficiency and productivity. Some key services include:
                    </p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Consulting Services</h5>
                                    <p className="card-text">Expert consulting to optimize strategies, enhance performance, and drive growth.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Training & Development</h5>
                                    <p className="card-text">Personalized programs to enhance skills, leadership capabilities, and knowledge.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Legal & Compliance</h5>
                                    <p className="card-text">Making sure businesses adhere to evolving regulations and industry standards while staying efficient.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Benefits of Professional Services */}
            <section className="benefits-of-professional-services py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Advantages of Our Professional Services</b></h5>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>Enhanced Efficiency:</strong> Streamlining processes and reducing operational bottlenecks.</li>
                        <li className="list-group-item"><strong>Expert Guidance:</strong> Leveraging industry specialists to navigate challenges and achieve goals.</li>
                        <li className="list-group-item"><strong>Flexible Scaling:</strong> Adapting to evolving business needs with scalable solutions.</li>
                        <li className="list-group-item"><strong>Regulatory Compliance:</strong> Staying compliant with industry standards through expert support.</li>
                    </ul>
                </div>
            </section>

            {/* Challenges Faced by Professionals */}
            <section className="challenges-faced-by-professionals py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Key Challenges Faced by Professionals</b></h5>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>Time Management:</strong> Balancing tasks efficiently can be overwhelming. Our solutions streamline workflows.</li>
                        <li className="list-group-item"><strong>Technology Adoption:</strong> Integrating new technologies without disruption can be challenging.</li>
                        <li className="list-group-item"><strong>Client Relationships:</strong> Maintaining strong connections and delivering quality service is crucial.</li>
                    </ul>
                </div>
            </section>

            {/* Closing Statement */}
            <Thank />

            {/* Bottom Content */}
            <BottomContent />

            {/* Dark Mode Button */}
            <DarkModeButton />

            {/* Contact Button */}
            <ContactButton />

            {/* Scroll to Top Button */}
            <ScrollButton />
        </>
    );
}
