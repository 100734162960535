import React from "react";
import { Link } from 'react-router-dom';
import Navbar from "../components/Navbar";
import BottomContent from "../components/BottomContent";
import ScrollButton from "../components/ScrollButton";
import Thank from "../components/Thank";
import DarkModeButton from "../components/DarkModeButton";
import ContactButton from "../components/ContactButton";
import "./Service.css";

export default function Service() {
  const services = [
    {
      imageURL: "media/img/DigitalHero.png",
      serviceName: "Digital",
      serviceDescription: "Leverage cutting-edge digital innovation to transform your business. Our services include digital strategy, creative and emerging engineering, AI automation practices (RPA/CPA), AI data science practices (DS/ML/DL, GenAI), quantum computing, cybersecurity, chatbot solutions, and blockchain & IoT practices.",
      link: "/digital",
      point: "Empowering Innovation through Advanced Digital Solutions",
    },
    {
      imageURL: "media/img/ConsultingHero.png",
      serviceName: "Advisory & Consulting",
      serviceDescription: "Vedalytics provides comprehensive advisory and consulting services to drive enterprise transformation and innovation. From strategy and architecture to data governance and innovation management, we offer solutions tailored to diverse business sectors, including education and healthcare.",
      link: "/consulting",
      point: "Guiding Business Success through Strategic Expertise",
    },
    {
      imageURL: "media/img/TechnologyHero.png",
      serviceName: "Technology",
      serviceDescription: "Achieve digital excellence with our technology services, including Agile lifecycle management, DevOps transformation, cloud and microservices adoption, API development, quality assurance, intelligent automation (RPA/CPA), and application development (.Net, Java, PWA, Big Data, GenAI). Our zero-maintenance, auto-heal IT support ensures smooth operations.",
      link: "/technology",
      point: "Driving Efficiency with Modern Technology Solutions",
    },
  ];

  

  return (
    <>
      <Navbar />
      <div className="mt-5" style={{ background: "var(--hero_linear)" }}>
        <div className="container text-center border-bottom border-top">
          <h1 className="mt-5" style={{color:'var(--content_color)'}}><b>Our Services</b></h1>
          <h3 className="mb-5" style={{color:'var(--content_color)'}}>
            Transform your business with Vedalytics
          </h3>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        {services.map((service, index) => (
          <div className="row mb-5 align-items-center" key={index}>
            {index % 2 === 0 ? (
              <>
                <div className="col-12 col-md-6 d-flex justify-content-center order-md-1 order-2">
                  <img src={service.imageURL} alt={service.serviceName} className="img-fluid rounded" style={{ maxWidth: "400px" }} />
                </div>
                <div className="col-12 col-md-6 text-center text-md-start order-md-2 order-1">
                  <h1 style={{ color: "var(--heading_color)" }}>{service.serviceName}</h1>
                  <h6 style={{ color: "var(--heading_color)" }}>{service.point}</h6>
                  <p className="fs-5 text-muted">{service.serviceDescription}</p>
                  <Link to={service.link} className="fs-5 btn btn-outline-primary">
                      Learn More <i className="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="col-12 col-md-6 text-center text-md-start">
                  <h1 style={{ color: "var(--heading_color)" }}>{service.serviceName}</h1>
                  <h6 style={{ color: "var(--heading_color)" }}>{service.point}</h6>
                  <p className="fs-5 text-muted">{service.serviceDescription}</p>
                  <a href={service.link} className="fs-5 btn btn-outline-primary">
                    Learn More <i className="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center order-first order-md-last">
                  <img src={service.imageURL} alt={service.serviceName} className="img-fluid rounded" style={{ maxWidth: "400px" }} />
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      <Thank />
      <BottomContent />
      <ContactButton />
      <DarkModeButton />
      <ScrollButton />
    </>
  );
}
