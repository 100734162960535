import React from 'react';

function Hero({title,des}) {
    return ( 
        <div className='' style={{background: 'var(--hero_linear)'}}>

        
        <div className='container text-center mt-5  border-bottom border-top'>
           <h1 className='mt-5' style={{color:'var(--content_color)'}}><b>{title}</b></h1>
           <h3 className='mb-5' style={{color:'var(--content_color)'}}>{des}</h3>
           
        </div>
        </div>
     );
}

export default Hero;