import React from 'react'
import BottomContent from './BottomContent'
import Navbar from './Navbar'
import ContactButton from './ContactButton'
import DarkModeButton from './DarkModeButton'
import ScrollButton from './ScrollButton'
import Thank from './Thank'

export default function Terms() {
  return (
    <div>
      <Navbar />
      <section>
        <div className="px-2 px-sm-2 px-md-5 px-lg-5 px-xl-5 py-2 py-sm-2 py-md-5 py-lg-5 py-xl-5">
          <div className="container my-5" style={{color:'var(--heading_color)'}}>
            <div className="text-center">
              <h2 className="mt-5 mt-sm-5 mt-md-3 mt-lg-3 mt-xl-3 px-3 px-sm-3 px-md-1 px-lg-3 px-xl-1">
                <b style={{color:'var(--heading_color)'}}>Terms & Conditions</b>
              </h2>
            </div>
            <div className="my-4">
              <p className="text-secondary">By accessing this website we assume you accept these terms and conditions in full.
                Do
                not continue to use Vedalytics website if you do not accept all of the terms and conditions stated on this
                page</p>
              <br />
              <p className="text-secondary">
                All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration,
                or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the
                Company’s stated services/products, in accordance with and subject to, prevailing law of India. Any use of
                the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are
                taken as interchangeable and therefore as referring to same.
              </p><br />
              <h5><b>Restrictions</b></h5>
              <div className="restrictions-inner-content">
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Publishing any Website material
                  in any other media</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Selling, sublicensing and/or
                  otherwise any Website material</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Publicly performing and/or
                  showing any Website material</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Using this Website in any way
                  that impacts user access to this Website</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Using this Website contrary to
                  applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business
                  entity</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Using this Website in any way
                  that impacts user access to this Website</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Engaging in any data mining,
                  data harvesting, data extracting or any other similar activity in relation to this Website</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Using this Website to engage in
                  any advertising or marketing</span><br />
              </div>
              <h5 className="mt-4"><b>Your Content</b></h5>
              <p className="text-secondary">By accessing this website we assume you accept these terms and conditions in full.
                Do not continue to use Vedalytics website if you do not accept all of the terms and conditions stated on
                this page. By accessing this website we assume you accept these terms and conditions in full. Do not
                continue to use Vedalytics website if you do not accept all of the terms and conditions stated on this page.
                By accessing this website we assume you accept these terms and conditions in full. Do not continue to use
                Vedalytics website if you do not accept all of the terms and conditions stated on this page</p>
              <p className="text-secondary">By accessing this website we assume you accept these terms and conditions in full.
                Do not continue to use Vedalytics website if you do not accept all of the terms and conditions stated on
                this page.</p>
              <h5 className="mt-4"><b>Limitations of Liability</b></h5>
              <p className="text-secondary">By accessing this website we assume you accept these terms and conditions in full.
                Do not continue to use Vedalytics website if you do not accept all of the terms and conditions stated on
                this page. By accessing this website we assume you accept these terms and conditions in full. Do not
                continue to use Vedalytics website if you do not accept all of the terms and conditions stated on this page.
                By accessing this website we assume you accept these terms and conditions in full. Do not continue to use
                Vedalytics website if you do not accept all of the terms and conditions stated on this page</p>
              <h5 className="mt-4"><b>Severability</b></h5>
              <p className="text-secondary">By accessing this website we assume you accept these terms and conditions in full.
                Do not continue to use Vedalytics website if you do not accept all of the terms and conditions stated on
                this page. By accessing this website we assume you accept these terms and conditions in full. Do not
                continue to use Vedalytics website if you do not accept all of the terms and conditions stated on this page.
                By accessing this website we assume you accept these terms and conditions in full. Do not continue to use
                Vedalytics website if you do not accept all of the terms and conditions stated on this page</p>
              <h5 className="mt-4"><b>Variation of Terms</b></h5>
              <div className="restrictions-inner-content">
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Publishing any Website material
                  in any other media</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Selling, sublicensing and/or
                  otherwise any Website material</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Publicly performing and/or
                  showing any Website material</span><br />
                <i className="bi bi-check-circle-fill text-primary"></i><span className="text-secondary">&nbsp;&nbsp;Using this Website in any way
                  that impacts user access to this Website</span><br />
              </div>
              <h5 className="mt-4"><b>Governing Law & Jurisdiction</b></h5>
              <p className="text-secondary">By accessing this website we assume you accept these terms and conditions in full.
                Do not continue to use Vedalytics website if you do not accept all of the terms and conditions stated on
                this page. By accessing this website we assume you accept these terms and conditions in full. Do not
                continue to use Vedalytics website if you do not accept all of the terms and conditions stated on this page.
                By accessing this website we assume you accept these terms and conditions in full. Do not continue to use
                Vedalytics website if you do not accept all of the terms and conditions stated on this page</p>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="bg-success p-5">
          <div className="d-flex  justify-content-center ">
            <h4 className="text-white  align-self-center">Are you Ready for Awesomeness ?</h4>
            <button class="btn bg-light text-success py-2 px-2 mx-3 justify-content-center align-self-center"><Link to='/contact' style={{ textDecoration: 'none' }}> Contact Us</Link></button>
          </div>
        </div>
      </section> */}
      <Thank />

      <BottomContent />
      <ContactButton />
      <DarkModeButton />
      <ScrollButton />
      {/* <Footer /> */}
    </div>
  )
}
