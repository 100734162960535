import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollTooTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the location changes
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

export default ScrollTooTop;
