import React from 'react'

export default function Hero({heading, subheading, text,img}){
    return(
        <>
        <div className="main-area p-4" style={{background: 'var(--hero_linear)',marginTop:'66px'}}>
            <div className="row mx-auto container my-5">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-3 mt-sm-3 mt-md-5 mt-lg-5 mt-xl-5">
                    <h3 className="f-color-light px-1 px-sm-1 px-md-1 px-lg-1 px-xl-1 text-primary">{heading}</h3>
                    <h3 className="head-main px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1 text-warning hf">{subheading}</h3>
                    <p className="f-color-light px-2 px-sm-2 px-md-1 px-lg-1 px-xl-1" style={{color:'var(--content_color)'}}>{text}</p>
                    
                </div>
                <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block justify-content-center align-self-center col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12" style={{ height: '60vh' }}>
                    <img className="bg-image img-fluid" src={img} alt="bg" style={{ width: '100%', objectFit: 'cover' ,marginLeft:'50px'}} />
                </div>
            </div>
        </div>
        
        </>
    )
}