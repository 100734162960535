import React from 'react';
import Navbar from '../../components/Navbar';
import BottomContent from '../../components/BottomContent';
import Hero from './Hero';
import ScrollButton from "../../components/ScrollButton";
import ContactButton from '../../components/ContactButton';
import Thank from '../../components/Thank';
import DarkModeButton from '../../components/DarkModeButton';
import '../Industries/IndStyle.css'

export default function CoManaged() {
    return (
        <>
            {/* Navbar Component */}
            <Navbar />

            {/* Hero Section */}
            <Hero
                title='Co-Managed Engagement'
                des='Seamlessly collaborate with Vedalytics to accelerate your projects with shared responsibilities and expertise.'
            />

            {/* Introduction Section */}
            <section className="co-managed-introduction py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Best of Both Worlds</b></h5>
                    <p className="" style={{color:"var(--heading_color)"}}>
                        The <b>Co-Managed Engagement</b> model fosters a strong partnership between your team and Vedalytics, blending your domain expertise with our AI capabilities to drive innovation and execution.
                    </p>
                </div>
            </section>

            {/* Key Benefits Section */}
            <section className="key-benefits py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>Why Choose Co-Managed Engagement?</b></h5>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm text-center p-4">
                                <h5 className="fw-bold">Balanced Responsibilities</h5>
                                <p className="text-muted">Both teams collaborate in decision-making, development, and execution.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm text-center p-4">
                                <h5 className="fw-bold">Continuous KT</h5>
                                <p className="text-muted">We empower your team with expertise while working alongside them.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm text-center p-4">
                                <h5 className="fw-bold">Enhanced Efficiency</h5>
                                <p className="text-muted">Leverage Vedalytics' AI resources while maintaining strategic control.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Workflow Section */}
            <section className="workflow py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>How It Works</b></h5>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">1. Define Scope</h5>
                                <p className="text-muted">Together, we define goals, roles, and collaboration models.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">2. Shared Development</h5>
                                <p className="text-muted">Both teams contribute to development and implementation.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card shadow-sm p-3">
                                <h5 className="fw-bold">3. Deploy & Optimize</h5>
                                <p className="text-muted">Continuous improvements and support ensure project success.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Closing Statement */}
            <Thank />

            {/* Bottom Content */}
            <BottomContent />

            {/* Dark Mode Button */}
            <DarkModeButton />

            {/* Contact Button */}
            <ContactButton />

            {/* Scroll to Top Button */}
            <ScrollButton />
        </>
    );
}
