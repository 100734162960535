import React from 'react';
import Navbar from '../../components/Navbar';
import BottomContent from '../../components/BottomContent';
import Hero from './Hero';
import ScrollButton from "../../components/ScrollButton";
import ContactButton from '../../components/ContactButton';
import Thank from '../../components/Thank';
import DarkModeButton from '../../components/DarkModeButton';
import './IndStyle.css'

export default function BfsiSystem() {
    return (
        <>
            {/* Navbar Component */}
            <Navbar />

            {/* Hero Section */}
            <Hero
                title='AI-Powered BFSI Solutions'
                des='Transforming Banking, Financial Services, and Insurance with AI and Automation for Better Customer Experience and Efficiency.'
            />

            {/* Introduction Section */}
            <section className="bfsi-introduction py-5 ">
                <div className="container">
                <h5 className="mb-4 text-primary text-uppercase"><b>Revolutionizing BFSI with AI & Automation</b></h5>
                <p className="" style={{color:"var(--heading_color)"}}>
                    At Vedalytics, we provide innovative AI-driven solutions tailored for the BFSI sector. Our advanced technologies optimize operations, enhance security, and improve customer satisfaction. From smarter banking operations to more efficient insurance claims processing, we empower the BFSI industry to lead the way in financial innovation.
                </p>
                </div>
            </section>

            {/* AI in BFSI Overview */}
            <section className="ai-in-bfsi py-5">
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>AI-Powered Solutions for BFSI</b></h5>
                    <p className="mb-4" style={{color:"var(--heading_color)"}}>
                    Transforming the BFSI sector with innovative AI solutions that enhance decision-making, elevate customer experiences, and optimize operational efficiency. Our offerings include:
                    </p>
                    <div className="row">
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-body text-center">
                                <h5 className="card-title">Fraud Prevention</h5>
                                <p className="card-text">Advanced AI algorithms detect and mitigate fraudulent activities by analyzing transaction patterns in real time, ensuring secure financial operations.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-body text-center">
                                <h5 className="card-title">Automated Customer Support</h5>
                                <p className="card-text">Leverage chatbots and virtual assistants to deliver round-the-clock customer support, enhancing satisfaction and streamlining service delivery.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-body text-center">
                                <h5 className="card-title">Risk Management</h5>
                                <p className="card-text">AI-powered insights help evaluate financial risks, develop investment strategies, and enable informed decision-making, empowering banks and insurance providers.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            {/* Benefits of AI in BFSI */}
            <section className="ai-benefits-in-bfsi py-5" >
                <div className="container">
                    <h5 className="mb-4 text-primary text-uppercase"><b>The Benefits of AI in BFSI</b></h5>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <strong>Enhanced Security:</strong> Leverage AI to detect and prevent fraudulent activities in real-time, ensuring robust protection for financial data.
                        </li>
                        <li className="list-group-item">
                            <strong>Operational Efficiency:</strong> Automate routine processes to optimize workflow, reduce costs, and boost productivity.
                        </li>
                        <li className="list-group-item">
                            <strong>Improved Customer Experience:</strong> Offer personalized and responsive support through chatbots and virtual assistants, enhancing client satisfaction.
                        </li>
                        <li className="list-group-item">
                            <strong>Data-Driven Decisions:</strong> Utilize AI-powered analytics to gain insights into financial trends, enabling informed decision-making and risk management.
                        </li>
                    </ul>
                </div>
            </section>

            {/* Challenges in AI Adoption in BFSI */}
            <section className="challenges-in-ai-bfsi py-5 ">
            <div className="container">
                <h5 className="mb-4 text-primary text-uppercase" ><b>Barriers to AI Adoption in BFSI</b></h5>
                <ul className="list-group">
                    <li className="list-group-item">
                        <strong>Data Privacy and Compliance:</strong> Safeguarding financial data while adhering to stringent regulations.
                    </li>
                    <li className="list-group-item">
                        <strong>Legacy Systems Integration:</strong> Overcoming challenges in integrating AI with outdated infrastructure and traditional workflows.
                    </li>
                    <li className="list-group-item">
                        <strong>Building Trust:</strong> Addressing concerns about the accuracy and security of AI-driven decisions among stakeholders.
                    </li>
                </ul>
            </div>
            </section>

            {/* Future Vision for AI in BFSI */}
            <section className="future-vision-ai-bfsi py-5">
                <div className="container">
                <h5 className="mb-4 text-primary text-uppercase">
                    <b>Shaping the Future of BFSI Through AI Innovation</b>
                </h5>
                <p className="" style={{color:"var(--heading_color)"}}>
                    We envision a future where AI and automation drive the BFSI sector forward. Through smarter risk management, improved customer interactions, and efficient operations, AI will help banking and financial institutions become more competitive and customer-centric. By bridging technology with financial expertise, we aim to create seamless, secure, and innovative financial services that empower both institutions and their clients.
                </p>
                </div>
            </section>

            {/* Closing Statement */}
            <Thank />

            {/* Bottom Content */}
            <BottomContent />

            {/* Dark Mode Button */}
            <DarkModeButton />

            {/* Contact Button */}
            <ContactButton />

            {/* Scroll to Top Button */}
            <ScrollButton />
        </>
    );
}
